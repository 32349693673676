<template>
  <div class="bom-wrapper">
    <Boms :pm="true" :pmProduct="basePn" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import Boms from '@/views/bomg/boms/Boms.vue';

  export default defineComponent({
    name: 'CertificationTab',
    components: {
      Boms,
    },
    props: {
      basePn: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        modal: true,
        modalTitle: '',
        bomData: {},
        uomOptions: [],
        certifiedFamilies: [],
        showMassUpdateModal: false,
        showImportModal: false,
        showSearchByMaterialModal: false,
        showRevokeDialog: false,
        showCancelDialog: false,
        showDeleteDialog: false,
      };
    },
    methods: {
      closeModal() {
        this.modal = false;
      },
      saveBom() {
        console.log('saveBom');
      },
    },
  });
</script>
