<!-- src/views/assemblies/Assemblies.vue -->
<template>
  <v-container fluid style="padding-top: 0px">
    <v-card class="pa-3">
      <v-tabs v-model="activeTab" style="border-bottom: 2px solid">
        <v-tab active data-cy="tab-overview">Overview</v-tab>
        <v-spacer></v-spacer>
        <v-select
          class="mr-3 filter-select"
          max-width="200"
          v-model="tableFilter"
          :items="tableFilterData"
          item-text="title"
          item-value="value"
          variant="underlined"
          data-cy="form-value-mode"
          prepend-inner-icon="mdi-filter"
        />
        <v-text-field
          class="mr-3"
          max-width="200"
          v-model="searchQuery"
          density="compact"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="underlined"
          hide-details
          single-line
          data-cy="search-input"
        ></v-text-field>

        <v-btn-group
          style="
            height: 36px;
            border-color: orange;
            box-shadow:
              0px 2px 4px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
              0px 4px 5px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
              0px 1px 10px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
          "
        >
          <v-btn class="btn-orange" @click="openModal('create')" data-cy="new-button">New</v-btn>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn class="btn-orange" v-bind="props" style="min-width: 12px; width: 24px; padding: 0px 0px 0px 0px">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="openModal('create')">
                <v-list-item-title>
                  <v-icon start>mdi-file-plus</v-icon>
                  Create New Assembly
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleImport">
                <v-list-item-title>
                  <v-icon start>mdi-file-import</v-icon>
                  Import file
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn-group>
      </v-tabs>

      <div v-if="activeTab === 0" data-cy="tab-content-overview">
        <v-data-table
          :headers="tableHeaders"
          :items="filteredComponents"
          item-value="id"
          class="custom-table"
          data-cy="data-table"
          loading-text="Loading... Please wait"
          :loading="loadingData"
          v-model:items-per-page="itemsPerPage"
          :items-per-page-options="itemsPerPageOptions"
          :sort-by="[{ key: 'name', order: 'asc' }]"
          :must-sort="true"
        >
          <template v-slot:item.name="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:item.description="{ item }">
            {{ item.description }}
          </template>
          <template v-slot:item.type="{ item }">
            {{ typeMapping[item.type] || item.type }}
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="controls-wrapper">
              <span @click="openModal('edit', item)"><i class="fa-duotone fa-solid fa-pen-to-square"></i></span>
              <span v-if="item.type == 'blueprint' || item.type == 'assembly'" @click="downloadFile(item)"><i class="fa-duotone fa-solid fa-download"></i></span>
              <span @click="confirmDeletion(item)"><i class="fa-duotone fa-solid fa-trash"></i></span>
            </div>
          </template>
        </v-data-table>
      </div>

      <div v-else data-cy="tab-content-details">
        <div class="text-center">
          <h2>Details</h2>
          <p>Information on Construction</p>
        </div>
      </div>
      <v-dialog v-model="isModalOpen" max-width="1200px" data-cy="edit-modal" persistent class="custom-dialog">
        <AssembliesModal
          :title="modalTitle"
          :current-item="currentItem"
          :available-attributes="attributesData"
          :available-components="componentsData"
          :available-materials="materialsData"
          :unitOptions="unitOptions"
          @close="closeModal"
          @save="saveItem"
        />
      </v-dialog>
      <Dialogs :deleteDialog="isDeleteDialogOpen" @closeDeleteDialog="closeDeleteDialog" @confirmDeleteItem="confirmDelete" />
      <v-file-input ref="fileInput" accept=".bomg" style="display: none" @change="processFile" />
      <v-overlay v-model="isImporting" class="align-center justify-center" :scrim="'rgba(255, 255, 255, 0.5)'" persistent>
        <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
        <div class="text-center mt-2" style="margin-left: -10px">Importing file...</div>
      </v-overlay>
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import AssembliesModal from './AssembliesModal.vue';
  import { ComponentItem, AttributeItem, MaterialItem } from '@/types/components';
  import { useAttributesStore } from '@/store/attributesStore';
  import { useUserPreferencesStore } from '@/store/userPreferencesStore';
  import Dialogs from '@/components/common/Dialogs.vue';
  import materialsService from '@/services/api/materialsService';
  import attributeService from '@/services/api/attributeService';
  import componentService from '@/services/api/componentService';
  import assemblyService from '@/services/api/assemblyService';
  import etcService from '@/services/api/etcService';

  export default defineComponent({
    components: {
      AssembliesModal,
      Dialogs,
    },

    data() {
      return {
        activeTab: 0,
        searchQuery: '',
        isModalOpen: false,
        isDeleteDialogOpen: false,
        modalTitle: '',
        deleteDialogText: '',
        deleteButton: true,
        currentItem: this.resetForm(),
        itemToDelete: null as ComponentItem | null,
        loadingData: false,
        tableHeaders: [
          { title: 'Name', value: 'name', sortable: true, width: '30%' },
          { title: 'Description', value: 'description', sortable: true, width: '30%' },
          { title: 'Code', value: 'code', sortable: true, width: '10%' },
          { title: 'Type', value: 'type', sortable: true, width: '20%' },
          { title: 'Actions', value: 'actions', sortable: false, width: '10%' },
        ],
        attributesData: [] as AttributeItem[],
        assemblyData: [] as ComponentItem[],
        componentsData: [] as ComponentItem[],
        materialsData: [] as MaterialItem[],
        tableFilter: 'All',
        tableFilterData: [
          { title: 'All', value: 'All' },
          { title: 'Blueprint', value: 'blueprint' },
          { title: 'Sub-Assembly', value: 'assembly' },
          { title: 'Code Values', value: 'values' },
        ],
        typeMapping: {
          blueprint: 'Blueprint',
          assembly: 'Sub-Assembly',
          values: 'Code Values',
        } as { [key: string]: string },
        itemsPerPage: 10,
        itemsPerPageOptions: [10, 25, 50, 100, -1],
        unitOptions: [] as any[],
        isImporting: false,
      };
    },

    computed: {
      filteredComponents(): ComponentItem[] {
        return this.assemblyData.filter((item) => {
          if (this.tableFilter !== 'All' && item.type !== this.tableFilter) {
            return false;
          }
          if (this.searchQuery) {
            const query = this.searchQuery.toLowerCase();
            return Object.values(item).some((value) => String(value).toLowerCase().includes(query));
          }
          return true;
        });
      },
    },

    mounted() {
      this.getAssemblyData();
      this.getAttributesList();
      this.getComponentsData();
      this.getMaterialsData();
      this.getUnits();
      this.restoreUserPreferences();
    },

    watch: {
      tableFilter(newFilter) {
        this.saveUserPreferences({ tableFilter: newFilter });
      },
      itemsPerPage(newItemsPerPage) {
        this.saveUserPreferences({ itemsPerPage: newItemsPerPage });
      },
    },

    methods: {
      handleImport() {
        if (this.$refs.fileInput) {
          (this.$refs.fileInput as HTMLInputElement).value = '';
        }
        (this.$refs.fileInput as HTMLInputElement).click();
      },
      async processFile(event: any) {
        const file = event.target.files[0];
        if (!file) return;

        this.isImporting = true;

        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            const content = e.target?.result;
            if (typeof content === 'string') {
              const parsedData: any = JSON.parse(content);
              const preparedData = this.prepareDataForImport(parsedData);
              const response = await assemblyService.reGenerateAssembly(preparedData);

              if (response && response.data) {
                if (response.data.warnings && response.data.warnings.length > 0) {
                  this.$swal.fire({
                    title: 'Import Warning',
                    html: `<div>
                      <p>The following attributes was not recognized and was removed during the import:</p>
                         <ul style="text-align: left; margin-top: 10px; list-style-type: disc; padding-left: 20px;">
                          ${response.data.warnings
                            .map((warning: string) => {
                              const match = warning.match(/name=([^;]+)/);
                              const attributeName = match ? match[1] : warning;
                              return `<li style="margin-bottom: 8px; color: #e65100; font-weight: 500;">
                              ${attributeName}
                            </li>`;
                            })
                            .join('')}
                        </ul>
                       
                    </div>`,
                    icon: 'warning',
                    confirmButtonText: 'CONTINUE',
                    confirmButtonColor: '#ff9800',
                  });
                }

                this.openModal('import', response.data);
              } else {
                this.$error.view('Error during import');
              }
            } else {
              throw new Error('File content is not a valid string');
            }
          } catch (error) {
            this.$error.view(error);
          } finally {
            this.isImporting = false;
          }
        };

        reader.onerror = () => {
          this.$error.view('Error reading file');
          this.isImporting = false; // Hide loader on error
        };

        reader.readAsText(file);
      },
      prepareDataForImport(data: any) {
        const { id, ...rest } = data;
        return rest;
      },
      async getAttributesList() {
        try {
          const attributesStore = useAttributesStore();
          const sortedAttributes = await attributeService.getAttributesList();
          this.attributesData = sortedAttributes;
          attributesStore.setAttributesData(sortedAttributes);
        } catch (error: any) {
          this.$error.view(error);
        }
      },

      async getComponentsData() {
        try {
          const sortedComponentTypes = await componentService.getComponentsList();
          this.componentsData = sortedComponentTypes;
        } catch (error: any) {
          this.$error.view(error);
        }
      },

      async getMaterialsData() {
        try {
          const response = await materialsService.getMaterialsList();
          if (response) {
            this.materialsData = response
              .map((item: any) => {
                return {
                  id: item.id,
                  name: item.odooMaterial?.description || 'No Name',
                } as MaterialItem;
              })
              .sort((a: MaterialItem, b: MaterialItem) => a.name.localeCompare(b.name));
          }
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      async getUnits() {
        try {
          this.unitOptions = await etcService.getUnits();
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      async openModal(action: 'create' | 'edit' | 'import', item: ComponentItem | null = null) {
        this.modalTitle = action === 'create' ? 'Create Assembly' : action === 'import' ? 'Import Assembly' : 'Edit Assembly';
        this.currentItem = item ? { ...item } : this.resetForm();
        this.isModalOpen = true;
      },

      closeModal() {
        this.isModalOpen = false;
      },

      async saveItem(preparedData: any) {
        try {
          if (!this.currentItem.id) {
            await this.createAssembly(preparedData);
          } else {
            await this.updateAssembly(this.currentItem.id, preparedData);
          }
        } catch (error) {
          console.error('Failed to save assembly:', error);
        }
      },

      confirmDeletion(item: ComponentItem) {
        if (item.components > 0) {
          this.deleteDialogText = `The Logic Module can't be deleted, because it is used in the following Materials: {list of Materials}`;
          this.deleteButton = false;
        } else {
          this.deleteDialogText = `Are you sure you want to delete <b>${item.name}</b>?<br>The operation can't be undone.`;
          this.deleteButton = true;
        }
        this.itemToDelete = item;
        this.isDeleteDialogOpen = true;
      },

      closeDeleteDialog() {
        this.isDeleteDialogOpen = false;
        this.itemToDelete = null;
      },

      async confirmDelete() {
        if (this.itemToDelete) {
          await this.deleteAssembly(this.itemToDelete.id!);
          this.assemblyData = this.assemblyData.filter((item) => item.id !== this.itemToDelete!.id);
          this.closeDeleteDialog();
        }
      },

      resetForm() {
        return {};
      },

      async getAssemblyData() {
        this.loadingData = true;
        try {
          const assemblyData = await assemblyService.getAssembliesList();
          this.assemblyData = assemblyData;
          this.loadingData = false;
        } catch (error: any) {
          this.loadingData = false;
          this.$error.view(error);
        }
      },

      async createAssembly(data: any) {
        try {
          const newAssembly = await assemblyService.createAssembly(data);
          this.assemblyData.push(newAssembly);
          this.showSuccessMessage('Successfully created');
          this.closeModal();
        } catch (error: any) {
          this.$error.view(error);
        }
      },

      async updateAssembly(id: string, data: any) {
        try {
          const updatedAssembly = await assemblyService.updateAssembly(id, data);
          this.assemblyData = this.assemblyData.map((item) => (item.id === id ? updatedAssembly : item));
          this.showSuccessMessage('Successfully updated');
          this.closeModal();
        } catch (error: any) {
          this.$error.view(error);
        }
      },

      async deleteAssembly(id: string) {
        try {
          await assemblyService.deleteAssembly(id);
          this.getAssemblyData();
        } catch (error: any) {
          this.$error.view(error);
        }
      },

      showSuccessMessage(message: string) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      },

      saveUserPreferences(preferences: { tableFilter?: string; itemsPerPage?: number }) {
        const userPreferencesStore = useUserPreferencesStore();
        if (preferences.tableFilter !== undefined) {
          userPreferencesStore.setAssembliesTableFilterStore(preferences.tableFilter);
        }
        if (preferences.itemsPerPage !== undefined) {
          userPreferencesStore.setAssembliesTableItemsPerPage(preferences.itemsPerPage);
        }
      },

      restoreUserPreferences() {
        const userPreferencesStore = useUserPreferencesStore();
        this.tableFilter = userPreferencesStore.pageTableFilters.assembliesTableFilterStore;
        this.itemsPerPage = userPreferencesStore.itemsPerPage.assembliesTableItemsPerPage;
      },

      downloadFile(item: ComponentItem, addComponentTypeName = true, skipAttributeNA = true) {
        try {
          if (!item || !item.name) {
            this.$log.showErrorMessage('Error: Invalid data for download');
            return;
          }

          const itemToDownload = JSON.parse(JSON.stringify(item));

          const processComponent = (component: any) => {
            if (addComponentTypeName && component.componentTypeId) {
              const foundComponent = this.componentsData.find((comp: ComponentItem) => comp.id === component.componentTypeId);
              if (foundComponent) {
                component.componentTypeName = foundComponent.name;
              }
            }

            if (component.attributeValues && Array.isArray(component.attributeValues)) {
              component.attributeValues = component.attributeValues.filter((attr: any) => !skipAttributeNA || attr.value !== 'N/A');
              component.attributeValues.forEach((attr: any) => {
                if (attr.attributeId) {
                  const foundAttribute = this.attributesData.find((a: AttributeItem) => a.id === attr.attributeId);
                  if (foundAttribute) {
                    attr.description = foundAttribute.name;
                  }
                }
              });
            }

            if (component.attributes && Array.isArray(component.attributes)) {
              component.attributes = component.attributes.filter((attr: any) => !skipAttributeNA || attr.value !== 'N/A');
              component.attributes.forEach((attr: any) => {
                if (attr.attributeId) {
                  const foundAttribute = this.attributesData.find((a: AttributeItem) => a.id === attr.attributeId);
                  if (foundAttribute) {
                    attr.description = foundAttribute.name;
                  }
                }
              });
            }

            if (component.children && Array.isArray(component.children)) {
              component.children.forEach((child: any) => processComponent(child));
            }

            if (component.components && Array.isArray(component.components)) {
              component.components.forEach((comp: any) => processComponent(comp));
            }

            if (component.component) {
              processComponent(component.component);
            }
          };

          processComponent(itemToDownload);

          const currentDate = new Date().toISOString().split('T')[0];
          const fileName = `${item.name.replace(/[/\\?%*:|"<>]/g, '_')}_${currentDate}.bomg`;
          const fileContent = JSON.stringify(itemToDownload, null, 2);
          const blob = new Blob([fileContent], { type: 'application/json' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');

          link.href = url;
          link.setAttribute('download', fileName);

          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);

          this.$log.showSuccessMessage('File downloaded successfully');
        } catch (error) {
          this.$error.view(error);
        }
      },
    },
  });
</script>

<style scoped>
  .v-data-table {
    margin-top: 20px;
  }
  .controls-wrapper {
    display: flex;
    justify-content: space-around;
    font-size: 18px;
  }
  .controls-wrapper span {
    cursor: pointer;
  }
  .controls-wrapper span > i:hover {
    color: orange;
  }
  .controls-wrapper span > i.fa-pen-to-square:hover {
    color: rgb(64, 64, 194);
  }
  .controls-wrapper span > i.fa-trash:hover {
    color: red;
  }
  .filter-select {
    margin: -9px 0px 0px 0px;
  }
  :deep(.v-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table tr:hover) {
    background-color: #f7f7f7;
  }
  :deep(.v-overlay__scrim) {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }
</style>
