<!-- src/views/generate/GenerateViewBom.vue -->
<template>
  <v-overlay v-model="loading" class="align-center justify-center" scrim="white" style="backdrop-filter: blur(4px)" persistent>
    <!-- <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular> -->
    <v-row>
      <v-col cols="12" md="12" class="content-col-wait">
        <div class="no-data" data-cy="details-content">
          <h2 data-cy="details-title">Please Wait</h2>
          <p data-cy="details-description">This may take some time.</p>
          <Loader class="mt-3" />
        </div>
      </v-col>
    </v-row>
  </v-overlay>
  <v-row data-cy="tab-content-bom">
    <v-col cols="12" md="12" class="mt-4" v-if="viewBom && viewBom.length">
      <h2 class="mb-3">Product info:</h2>

      <v-row>
        <v-col cols="12" md="12" style="padding: 0px 12px 0px 24px">
          <v-text-field v-model="description" label="Description" variant="underlined"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col cols="12" md="4" style="padding: 0px 12px 0px 24px">
          <v-select label="Routing" :items="routingList" v-model="selectedRouting" variant="underlined"></v-select>
        </v-col> -->
        <v-col cols="12" md="12" style="padding: 0px 12px 0px 24px">
          <v-text-field v-model="customerDescription" label="Customer Description (optional)" variant="underlined"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" style="padding: 0px 12px 0px 24px">
          <v-select label="Inventory Routing" :items="invRouting" v-model="selectedInvRouting" variant="underlined"></v-select>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0px 12px 0px 24px">
          <v-text-field v-model="customerPN" label="Customer PN (optional)" variant="underlined" :error-messages="customerPnError" @blur="validateAwsCustomerPn"></v-text-field>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="12" style="padding: 0px 12px">
      <div v-if="viewBom && viewBom.length" class="table-div">
        <h2>BOM:</h2>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="selectedReference" label="Reference" variant="underlined"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" style="padding: 12px 12px 0px 24px">
            <v-select label="Routing" :items="routingList" v-model="selectedRouting" variant="underlined"></v-select>
          </v-col>
        </v-row>
        <div class="button-container">
          <v-btn class="btn-orange save-to-reference" @click="saveBom" data-cy="save-bom">
            <i class="fa-solid fa-cloud-arrow-up"></i>
            &nbsp; Save BOM
          </v-btn>
          <v-btn class="btn-orange save-to-cert" @click="certBom" data-cy="cert-bom">
            <i class="fa-solid fa-file-certificate"></i>
            &nbsp; Certify BOM
          </v-btn>
        </div>
        <BomDataTable :bomList="viewBom" />

        <v-row class="d-flex align-center justify-space-between mt-4 mb-4">
          <v-col cols="auto" class="d-flex align-center">
            <v-btn class="btn-orange mr-4" @click="showPolarity" data-cy="polarity">
              <span>Polarity MAP</span>
            </v-btn>
            <v-btn v-if="bomHasVirtualMaterials" class="btn-orange" @click="createVirtualMaterials" data-cy="create-virtual-materials">
              <span>Create Virtual materials</span>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="d-flex align-center">
            <!-- <span class="mr-2">Use Odoo v13</span> -->
            <!-- <v-checkbox v-model="legacyOdoo" class="mr-4" data-cy="create-in-odoo" hide-details></v-checkbox> -->
            <v-btn class="btn-orange" @click="createOdoo" data-cy="create-in-odoo">
              <span v-if="legacyOdoo === false">Create in Odoo v17</span>
              <span v-else>Create in Odoo</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <div v-else class="text-center no-data" data-cy="details-content">
        <div v-if="!loadingData">
          <h2 data-cy="details-title">No Product Data</h2>
          <p data-cy="details-description">
            Please write
            <strong>PN</strong>
            and press
            <span class="mini-btn"><strong style="color: orange">GENERATE</strong></span>
            button
          </p>
        </div>
        <v-skeleton-loader v-else type="heading, table-row-divider@6" :loading="loadingData" rows="5" row-height="30" class="mt-5" />
      </div>
    </v-col>
  </v-row>
  <!-- <createNewBom :modal="modal" :modalTitle="modalTitle" :bomData="bomData" :uomOptions="uomOptions" :families="certifiedFamilies" @close="closeModal" @save="saveBom" /> -->
  <PolarityModal :showPolarityModal="showPolarityModal" :polarityData="polarityData" @save="savePolarity" @close="closePolarity" />
  <createNewBom :modal="modal" :modalTitle="modalTitle" :bomData="viewBomCreate" :families="[]" @close="closeModal" @save="closeModal" />
</template>

<script lang="ts">
  import { defineComponent, PropType, version } from 'vue';
  import odooService from '@/services/api/odooService';
  import materialsService from '@/services/api/materialsService';
  import bomService from '@/services/api/bomService';
  import BomDataTable from '@/components/bomg/bom/BomTable.vue';
  import createNewBom from '../boms/BomsModal.vue';
  import { useAuthStore } from '@/store/authStore';
  import { useProductStore } from '@/store/productStore';
  import Loader from '@/components/bomg/Loader.vue';
  import PolarityModal from '@/components/bomg/polarity/PolarityModal.vue';
  import { c } from 'naive-ui';

  export default defineComponent({
    name: 'GenerateViewBom',
    inheritAttrs: false,
    components: {
      BomDataTable,
      createNewBom,
      Loader,
      PolarityModal,
    },
    props: {
      viewBom: {
        type: Array as PropType<Array<Record<string, any>>>,
        required: false,
        default: () => [],
      },
      odooAttributes: {
        type: Array as PropType<Array<Record<string, any>>>,
        required: false,
        default: () => [],
      },
      product_info: {
        type: String as PropType<string>,
        required: false,
        default: () => '',
      },
      generation_item: {
        type: String as PropType<string>,
        required: false,
        default: () => '',
      },
      loadingData: {
        type: Boolean,
        required: true,
      },
      originalBom: {
        type: Array as PropType<Array<Record<string, any>>>,
        required: false,
        default: () => [],
      },
      items: {
        type: Array as PropType<Array<Record<string, any>>>,
        required: false,
        default: () => [],
      },
    },
    data() {
      return {
        loading: false,
        description: '' as string,
        selectedReference: '',
        routingList: ['Standard'],
        selectedRouting: 'Standard',
        legacyOdoo: true,
        modalTitle: '',
        modal: false,
        viewBomCreate: {},
        invRouting: ['Manufacture (VMTO)', 'Purchase', 'Sub-Contract'],
        selectedInvRouting: 'Manufacture (VMTO)',
        customerPN: '',
        customerDescription: '',
        productStore: useProductStore(),
        showPolarityModal: false,
        customerPnError: null as string | null,
      };
    },
    computed: {
      polarityData: {
        get() {
          return this.productStore.currentProduct.polarity || [];
        },
        set(value: any) {
          this.productStore.updatePolarity(value);
        },
      },
      bomHasVirtualMaterials() {
        return this.viewBom.some((bomLine) => bomLine.materials[0].virtual);
      },
    },

    mounted() {
      this.getOdooRoutings();
      this.populateReference();
      this.populateDescription();
      this.populateProductStore();
    },
    watch: {
      product_info(newVal) {
        if (newVal) {
          this.description = newVal.trim() || '';
        }
      },

      description(newVal) {
        this.productStore.updateCurrentProduct({ description: newVal });
      },
      customerDescription(newVal) {
        this.productStore.updateCurrentProduct({ customerDescription: newVal });
      },
      selectedInvRouting(newVal) {
        this.productStore.updateCurrentProduct({ invRouting: newVal });
      },
      selectedRouting(newVal) {
        this.productStore.updateCurrentProduct({ routing: newVal });
      },
      customerPN(newVal) {
        this.productStore.updateCurrentProduct({ customerPn: newVal });
        this.validateAwsCustomerPn();
      },
      generation_item(newVal) {
        this.productStore.updateCurrentProduct({ productPn: newVal });
      },
    },
    methods: {
      populateReference() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        this.selectedReference = `Reference ${month}/${day}/${year}`;
      },
      populateDescription() {
        this.description = this.product_info || '';
      },

      populateProductStore() {
        this.productStore.updateCurrentProduct({
          description: this.description,
          customerDescription: this.customerDescription,
          invRouting: this.selectedInvRouting,
          routing: this.selectedRouting,
          customerPn: this.customerPN,
          productPn: this.generation_item,
        });
      },
      async getOdooRoutings() {
        try {
          const response = await odooService.odooRoutings();
          this.routingList = response.map((item: { name: string }) => item.name).sort((a: string, b: string) => a.localeCompare(b));
        } catch (error) {
          this.$log.showError(`Failed to fetch Odoo routings: ${error}`);
        }
      },

      async saveBom() {
        try {
          const processedBomLines = this.viewBom.map((item) => ({
            ...item,
            material: Array.isArray(item.materials) ? item.materials[0] : item.materials,
          }));

          const data = {
            referenceType: 'reference',
            bomSource: 'generated',
            generatedFrom: this.generation_item,
            description: this.description,
            productPn: this.generation_item,
            reference: this.selectedReference,
            routing: this.selectedRouting,
            bomLines: processedBomLines,
            createdBy: useAuthStore().user?.name || 'Unknown',
            inventoryRouting: this.selectedInvRouting,
            ...(this.customerPN && { customerPn: this.customerPN }),
            ...(this.customerDescription && { customerDescription: this.customerDescription }),
          };

          const response = await bomService.bomCreate(data);

          this.$log.showSuccess('BOM saved To Reference');
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      saveBomLocal() {
        const processedBomLines = this.viewBom.map((item) => {
          return {
            ...item,
            material: item.materials[0],
          };
        });

        const data = {
          referenceType: 'reference',
          bomSource: 'generated',
          generatedFrom: this.generation_item,
          productPn: this.generation_item,
          reference: this.selectedReference,
          routing: this.selectedRouting,
          bomLines: processedBomLines,
        };

        localStorage.setItem('bom_list', JSON.stringify(data));
        this.$log.showSuccess('BOM saved in LocalStorage');
      },
      async createVirtualMaterials() {
        const virtualMaterials = this.viewBom.filter((bomLine) => bomLine.materials[0].virtual).map(({ materials }) => materials[0]);

        if (!virtualMaterials.length) return;

        this.loading = true;
        try {
          const response = await materialsService.createVirtualMaterials(virtualMaterials);
          if (!response?.length) return;

          response.forEach(({ odooMaterial }: { odooMaterial: { pn: string; description: string } }) => {
            //  original BOM
            const bomLine = this.originalBom.find((line) => line.materials[0].pn === odooMaterial.pn);
            if (bomLine) {
              bomLine.materials[0].description = odooMaterial.description;
              delete bomLine.materials[0].virtual;
            }

            // tree items
            const replaceVirtualMaterial = (items: any[]) => {
              items.forEach((item, index, array) => {
                if (item.virtual === true && item.odooMaterial?.pn === odooMaterial.pn) {
                  array[index] = { odooMaterial };
                }

                ['children', 'materials'].forEach((prop) => {
                  if (item[prop] && Array.isArray(item[prop])) {
                    replaceVirtualMaterial(item[prop]);
                  }
                });
              });
            };

            // replace
            replaceVirtualMaterial(this.items);
          });
        } catch (error) {
          this.$error.view(error);
        } finally {
          this.loading = false;
        }
      },

      async createOdoo() {
        try {
          const data = {
            pn: this.generation_item,
            description: this.description,
            routing: this.selectedRouting,
            inventoryRouting: this.selectedInvRouting,
            reference: this.selectedReference,
            lines: this.viewBom,
            legacyOdoo: this.legacyOdoo,
            attributes: this.odooAttributes,
          };
          //  console.log(data);
          const response = await odooService.createInOdoo(data);
          const success = response?.result?.success;
          const product_id = response?.result?.product_id;
          const message = response?.result?.message;

          if (success) {
            this.showSuccessMessage('Successfully added');
            this.showOdooMessageWithLink(product_id, message);
          } else {
            this.$log.showError(`Failed to create BOM: ${message}`);
          }
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      showSuccessMessage(message: string) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      },
      showOdooMessageWithLink(product_id: number, message: string) {
        const baseUrl = this.legacyOdoo ? 'https://staging.erp.viaphoton.com/web#id=' : 'https://erp17.viaphoton.dev/web#id=';

        const url = `${baseUrl}${product_id}&cids=1&menu_id=${this.legacyOdoo ? 142 : 285}&action=${this.legacyOdoo ? 283 : 436}&model=product.template&view_type=form`;

        const text = `
                                ${message} <br>
                                <a href="${url}" target="_blank">
                                  Open in Odoo
                                </a>
                              `;

        this.$swal.fire({
          toast: true,
          position: 'top',
          icon: 'success',
          html: text,
          showConfirmButton: true,
          timer: 60000,
          timerProgressBar: true,
        });
      },
      certBom() {
        this.modal = true;
        this.modalTitle = 'Certify BOM';
        this.viewBomCreate = {
          referenceType: 'reference',
          bomSource: 'generated',
          generatedFrom: this.generation_item,
          description: this.description,
          productPn: this.generation_item,
          reference: this.selectedReference,
          routing: this.selectedRouting,
          bomLines: this.viewBom,
          createdBy: useAuthStore().user?.name || 'Unknown',
          inventoryRouting: this.selectedInvRouting,
          ...(this.customerPN && { customerPn: this.customerPN }),
          ...(this.customerDescription && { customerDescription: this.customerDescription }),
        };
      },
      closeModal() {
        this.modal = false;
      },
      showPolarity() {
        if ((this.polarityData as any).connectorsData?.length === 0 || (this.polarityData as any).connectorsData?.every((arr: any[]) => arr.length === 0)) {
          this.$log.showError('ConnectorsData is empty');
          return;
        }

        if ((this.polarityData as any).error) {
          this.$log.showError((this.polarityData as any).error);
          return;
        }

        console.log('START', this.polarityData);
        this.showPolarityModal = true;
      },
      savePolarity(updatedData: any) {
        console.log(updatedData);
        useProductStore().updatePolarity(updatedData);
        this.polarityData = updatedData;
        this.showPolarityModal = false;
      },

      closePolarity() {
        this.showPolarityModal = false;
      },
      validateAwsCustomerPn() {
        const pn = this.customerPN;
        this.customerPnError = null;

        if (pn && pn.startsWith('VP1')) {
          if (pn.length !== 13) {
            this.customerPnError = 'AWS part numbers should be 13 characters';
          }
        }
      },
    },
  });
</script>

<style scoped>
  .no-data {
    padding-top: 62px;
  }
  .mini-btn {
    font-size: 0.8rem;
    border: 1px solid orange;
    padding: 2px 5px;
    border-radius: 5px;
  }

  ::v-deep(.v-messages__message) {
    color: #f44336;
    font-size: 12px;
    padding-top: 4px;
  }

  ::v-deep(.v-data-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table tr:hover) {
    background-color: #f7f7f7;
  }

  .table-div {
    position: relative;
  }
  .table-desc {
    color: #5e5e5e;
    font-size: 14px;
  }
  .button-container {
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
    justify-content: flex-end;
  }
  .content-col-wait {
    padding: 0px 12px;
    margin-top: -20px;
    width: 450px;
  }
  .no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
</style>
