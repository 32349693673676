<template>
  <div>
    <Drawings :items="drawings" :polarityData="polarityData" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import Drawings from '@/views/pm/temp/GenerateDrawings_PM.vue';

  export default defineComponent({
    name: 'DrawingsTab',
    components: {
      Drawings,
    },

    data() {
      return {
        drawings: [
          {
            id: 1,
            name: 'Drawing 1',
            description: 'Description 1',
          },
        ],
        polarityData: [
          {
            id: 1,
            name: 'Polarity 1',
            description: 'Description 1',
          },
        ],
      };
    },
  });
</script>
