<template>
  <div class="assembly-wrapper">
    <AssembliesModal
      :title="modalTitle"
      :current-item="state.currentItem"
      :available-attributes="state.attributesData"
      :available-components="state.componentsData"
      :available-materials="state.materialsData"
      :unitOptions="state.unitOptions"
      :pm="true"
      @update:item="handleItemUpdate"
    />
  </div>
  <!-- <pre>{{ productDetailsStore.getProduct()?.component || 'no component' }}</pre>
  <pre>{{ state.currentItem }}</pre> -->
</template>

<script setup lang="ts">
  import { reactive, computed, onMounted, watch } from 'vue';
  import AssembliesModal from '@/views/bomg/assemblies/AssembliesModal.vue';
  import attributeService from '@/services/api/attributeService';
  import componentService from '@/services/api/componentService';
  import materialsService from '@/services/api/materialsService';
  import etcService from '@/services/api/etcService';
  import { useAttributesStore } from '@/store/attributesStore';
  import { useProductDetailsStore } from '@/store/PM/productDetails';
  import { MaterialItem, AttributeItem, ComponentItem } from '@/types/components';

  interface Blueprint {
    id: string;
    type: 'blueprint';
    name: string;
    description: string;
    code: string;
    baseProduct: string;
    component: any | null;
  }

  interface State {
    attributesData: AttributeItem[];
    componentsData: ComponentItem[];
    materialsData: MaterialItem[];
    unitOptions: any[];
    currentItem: Blueprint;
  }

  // Props
  const props = defineProps({
    basePn: {
      type: String,
      required: true,
    },
    pmDescription: {
      type: String,
      required: true,
    },
  });

  // Store
  const productDetailsStore = useProductDetailsStore();
  const attributesStore = useAttributesStore();

  // Data
  const state = reactive<State>({
    attributesData: [],
    componentsData: [],
    materialsData: [],
    unitOptions: [],
    currentItem: {
      id: '',
      type: 'blueprint',
      name: '',
      description: props.pmDescription,
      code: '',
      baseProduct: props.basePn,
      component: productDetailsStore.getProduct()?.component ?? null,
    },
  });

  const modalTitle = computed(() => (productDetailsStore.getProduct()?.component ? 'Edit Assembly' : 'Blueprint'));

  const handleItemUpdate = (updatedItem: Blueprint) => {
    state.currentItem = updatedItem;
    productDetailsStore.setProduct({
      ...productDetailsStore.getProduct(),
      component: updatedItem.component,
    });
  };

  const fetchData = async () => {
    try {
      const [attributes, components, materials, units] = await Promise.all([
        attributeService.getAttributesList(),
        componentService.getComponentsList(),
        materialsService.getMaterialsList(),
        etcService.getUnits(),
      ]);

      state.attributesData = attributes;
      attributesStore.setAttributesData(attributes);
      state.componentsData = components;

      state.materialsData =
        materials
          ?.map((item: any) => ({
            id: item.id,
            name: item.odooMaterial?.description || 'No Name',
          }))
          .sort((a: MaterialItem, b: MaterialItem) => a.name.localeCompare(b.name)) ?? [];

      state.unitOptions = units;
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  //  blueprint initialization
  const initializeBlueprint = () => {
    const storedProduct = productDetailsStore.getProduct();

    state.currentItem = {
      id: '',
      type: 'blueprint',
      name: '',
      description: props.pmDescription,
      code: '',
      baseProduct: props.basePn,
      component: storedProduct?.component ?? null,
    };
  };

  watch(
    () => productDetailsStore.getProduct()?.component,
    (newComponent) => {
      if (newComponent) {
        state.currentItem.component = newComponent;
      }
    },
    { deep: true }
  );

  onMounted(async () => {
    await fetchData();
    initializeBlueprint();
  });
</script>
