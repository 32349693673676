<template>
  <v-container fluid data-cy="container" style="padding-top: 0px">
    <v-card class="pa-3" style="border: none" data-cy="main-card">
      <v-tabs v-model="tab" style="flex-grow: 1; border-bottom: 2px solid" data-cy="tabs">
        <v-tab
          :key="0"
          :active="activeTab === 0"
          @click="
            activeTab = 0;
            router.push('/products');
          "
          data-cy="tab-saved"
        >
          <span v-if="!router.currentRoute.value.params.id" class="d-flex align-center">
            <i class="fa-solid fa-list"></i>
            &nbsp; Products
          </span>
          <span v-else @click="router.back()">
            <i class="fa-solid fa-backward"></i>
            Back to list
          </span>
        </v-tab>

        <v-spacer></v-spacer>
        <v-btn v-if="!router.currentRoute.value.params.id && router.currentRoute.value.name !== 'NewProduct'" class="btn-orange" @click="navigateToNewProduct">
          Add new product
        </v-btn>
      </v-tabs>

      <div v-if="!router.currentRoute.value.params.id && router.currentRoute.value.name !== 'NewProduct'" class="products-list">
        <div v-if="tab === 0" data-cy="tab-content-saved">
          <div class="main-table">
            <v-data-table :headers="headers" :items="Object.keys(groupedByCategory)" :loading="loading">
              <template v-slot:item.name="{ item }">
                <div class="d-flex align-center product-cell" @click="toggleFamily(item)">
                  <v-icon size="small" class="mr-2">
                    {{ expandedProducts.includes(item) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
                  </v-icon>
                  {{ getCategoryName(item) }} ({{ groupedByCategory[item].length }})
                </div>
                <div v-if="expandedProducts.includes(item)" class="product-details mt-2">
                  <v-table density="compact" class="nested-table">
                    <thead>
                      <tr>
                        <th class="part-number">Part Number</th>
                        <th class="description">Description</th>
                        <th class="actions">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="product in groupedByCategory[item]" :key="product.id" class="nested-row">
                        <td class="part-number">{{ product.basePn }}</td>
                        <td class="description">{{ product.description }}</td>
                        <td class="actions">
                          <div class="controls-wrapper">
                            <span @click.stop="navigateToProduct(product)">
                              <i class="fa-duotone fa-solid fa-pen-to-square"></i>
                            </span>
                            <span @click.stop="confirmRemove(product)">
                              <i class="fa-duotone fa-solid fa-trash"></i>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </v-table>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>

      <router-view @product-updated="getProducts" @product-created="getProducts"></router-view>

      <Dialogs :deleteDialog="deleteDialog !== null" @confirmDeleteItem="confirmDeleteItem" @closeDeleteDialog="closeDeleteDialog" />
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { Product, Customer, Vendor, ProductCategory } from '@/types/pm/product';
  import productsService from '@/services/api_PM/products';
  import productsCategoryService from '@/services/api_PM/productsCategory';
  import Dialogs from '@/components/common/Dialogs.vue';

  interface ErrorWithMessage {
    message: string;
    [key: string]: any;
  }

  interface GroupedProducts {
    [key: string]: Product[];
  }

  export default defineComponent({
    name: 'Products',
    components: {
      Dialogs,
    },
    data: () => ({
      tab: 0,
      activeTab: 0,
      headers: [
        {
          title: 'Products Category',
          key: 'name',
          sortable: true,
        },
      ],
      loading: false,
      expandedProducts: [] as string[],
      selectedFamily: null as ProductCategory | null,
      revokeDialog: false,
      deleteDialog: null as Product | null,
      productCategory: [] as ProductCategory[],
      products: [] as Product[],
      groupedByCategory: {} as GroupedProducts,
    }),

    setup() {
      const router = useRouter();
      return { router };
    },

    mounted() {
      this.getProductCategory().then(() => {
        this.getProducts();
      });
    },

    methods: {
      async getProductCategory() {
        try {
          this.productCategory = await productsCategoryService.getProductsCategoryList();
        } catch (error) {
          this.$error.view(this.formatError(error));
        }
      },

      async getProducts() {
        try {
          this.loading = true;
          this.products = await productsService.getProducts();

          const routeId = this.router.currentRoute.value.params.id;
          if (routeId && typeof routeId === 'string') {
            const selectedProduct = this.products.find((p) => p.id === routeId);
            if (selectedProduct) {
            }
          }

          this.groupProductsByCategory();
          this.loading = false;
        } catch (error) {
          this.loading = false;
          this.$error.view(this.formatError(error));
        }
      },

      groupProductsByCategory() {
        this.groupedByCategory = {};

        const productsWithInvalidCategory = this.products.filter(
          (product) => !product.categoryId || !this.productCategory.some((category) => String(category.id) === String(product.categoryId))
        );

        if (productsWithInvalidCategory.length > 0) {
          if (!this.productCategory.some((category) => String(category.id) === 'NONE')) {
            const noneCategory: ProductCategory = {
              id: 1,
              name: 'Uncategorised',
              displayName: 'Uncategorised',
              parentId: 0,
            };
            this.productCategory.push(noneCategory);
          }

          productsWithInvalidCategory.forEach((product) => {
            product.categoryId = 'NONE';
          });
        }

        this.products.forEach((product) => {
          const categoryId = product.categoryId || 'NONE';
          if (!this.groupedByCategory[categoryId]) {
            this.groupedByCategory[categoryId] = [];
          }
          this.groupedByCategory[categoryId].push(product);
        });
      },

      getCategoryName(categoryId: string | number): string {
        const category = this.productCategory.find((cat) => String(cat.id) === String(categoryId));
        return category?.name ?? 'Unknown Category';
      },

      toggleFamily(categoryId: string) {
        const index = this.expandedProducts.indexOf(categoryId);
        if (index === -1) {
          this.expandedProducts.push(categoryId);
        } else {
          this.expandedProducts.splice(index, 1);
        }
      },

      formatCustomers(customers: Customer[]): string {
        return customers.map((customer) => customer.name).join(', ');
      },

      formatVendors(vendors: Vendor[]): string {
        return vendors.map((vendor) => vendor.name).join(', ');
      },

      formatError(error: unknown): string {
        if (typeof error === 'string') {
          return error;
        }
        const errorWithMessage = error as ErrorWithMessage;
        return errorWithMessage.message || 'Unknown error occurred';
      },

      async confirmDeleteItem() {
        if (this.deleteDialog) {
          try {
            await productsService.deleteProduct(this.deleteDialog.id);
            this.groupProductsByCategory();
            this.closeDeleteDialog();
            this.getProducts();
          } catch (error) {
            this.$error.view(this.formatError(error));
          }
        }
      },

      confirmRemove(product: Product) {
        this.deleteDialog = product;
      },

      closeDeleteDialog() {
        this.deleteDialog = null;
      },

      navigateToProduct(product: Product) {
        this.router.push(`/products/${product.id}`);
      },

      navigateToNewProduct() {
        this.router.push('/products/new');
      },
    },
  });
</script>

<style scoped>
  .certified-product-families-container {
    margin-top: 20px;
  }

  .product-cell {
    cursor: pointer;
    padding: 8px 0;
    height: 52px !important;
  }

  .product-details {
    background-color: #f8fafc;
    border-radius: 4px;
    margin: 8px 0;
  }

  .nested-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  .part-number {
    width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .description {
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .customers {
    width: 15%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .vendors {
    width: 15%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .actions {
    width: 10%;
    text-align: center;
  }
  .nested-table th {
    background-color: #f1f5f9;
    color: #475569;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: left;
    padding: 8px 16px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .nested-table td {
    padding: 8px 16px;
    font-size: 0.875rem;
    color: #1e293b;
    border-bottom: 1px solid #e2e8f0;
  }

  .nested-row:hover {
    background-color: #f1f5f9;
  }

  .controls-wrapper {
    padding: 0 8px;
    gap: 15px;
    display: flex;
    font-size: 18px;
  }

  .controls-wrapper span {
    cursor: pointer;
  }

  .controls-wrapper span > i:hover {
    color: orange;
  }

  .controls-wrapper span > i.fa-pen-to-square:hover {
    color: rgb(64, 64, 194);
  }

  .controls-wrapper span > i.fa-trash:hover {
    color: red;
  }
  :deep(v-data-table__tr) {
    padding-top: 29px;
    height: 52px;
  }
</style>
