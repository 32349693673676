<template>
  <v-dialog v-model="isWizardModalVisible" max-width="1300px" data-cy="modal" persistent class="custom-dialog">
    <v-card>
      <v-card-title class="headline">
        Start Wizard
        <v-icon small @click="handleCloseModal" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pt-4">
        <div class="part-number-display">
          <h2>Part Number: {{ formattedPartNumber }}</h2>
        </div>

        <div class="configurator-grid">
          <v-row>
            <v-col cols="2">
              <v-autocomplete
                v-model="selectedProductFamily"
                :items="availableProductFamilies"
                item-title="label"
                :item-value="getSelectOption as any"
                label="Product family"
                outlined
                dense
                hide-details
                class="config-select"
                :disabled="isEditMode"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" v-for="(componentType, index) in availableComponentTypes">
              <v-autocomplete
                v-if="!compatibilityOptions[componentType.id]?.some((compatibility) => compatibility.isUserInput)"
                v-model="selectedComponents[componentType.id]"
                :items="compatibilityOptions[componentType.id] || []"
                :item-title="(item: any) => item.component?.label || ''"
                :item-value="getSelectOption as any"
                :label="componentType.label"
                outlined
                dense
                hide-details
                class="config-select"
                :disabled="!arePreviousComponentsSelected(index)"
                :placeholder="compatibilityOptions[componentType.id]?.length ? '' : 'No options available'"
              ></v-autocomplete>
              <v-combobox
                v-else
                v-model="selectedComponents[componentType.id]"
                :items="compatibilityOptions[componentType.id]?.some((compatibility) => compatibility.component) ? compatibilityOptions[componentType.id] : []"
                :item-title="(item: any) => item.component?.label || ''"
                :item-value="getSelectOption as any"
                :label="componentType.label"
                outlined
                dense
                hide-details
                class="config-select"
                auto-select-first="exact"
                :disabled="!arePreviousComponentsSelected(index)"
                :placeholder="compatibilityOptions[componentType.id]?.length ? '' : 'No options available'"
              ></v-combobox>
            </v-col>
          </v-row>
          <div class="grid-row">
            <div class="spacer"></div>
            <div class="spacer"></div>
            <div class="spacer"></div>
            <div class="spacer"></div>

            <div class="button-group">
              <v-btn class="btn-cancel mr-2" @click="resetConfiguration">CLEAR CONFIGURATOR</v-btn>
              <!-- <v-btn class="btn-save" @click="getPnData(basePn)">Use this Base PN {{ basePn }}</v-btn> -->
            </div>
          </div>
        </div>

        <div class="preview-section mt-6">
          <h3>Generated Description:</h3>
          <p>{{ pnDescription }}</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="handleCloseModal">Cancel</v-btn>
        <v-btn :disabled="!isConfigurationComplete" class="btn-save" @click="handleSaveConfiguration">Use this Base PN</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { Product } from '@/types/pm/product';
  import WizardService from '@/services/api_PM/wizard';
  import Swal from 'sweetalert2';

  interface ComponentType {
    id: string;
    pg_id?: string;
    label: string;
    schemaCode: string;
    components?: Component[];
    productFamilies?: ProductFamily[];
  }

  interface Component {
    id: string;
    label: string;
    code: string;
  }

  interface CompatibilityOption {
    component?: Component;
    isDefaultComponent?: boolean;
    isUserInput?: boolean;
  }

  interface SchemaComponentType {
    id?: string;
    orderInGroup: number;
    groupInSchema: number;
    productFamily?: Partial<ProductFamily>;
    componentType: ComponentType;
  }

  interface ProductFamily {
    id: string;
    code: string;
    label: string;
    segments: any[];
    productType: any;
    compatibilities: any[];
    componentTypes: ComponentType[];
    schemaComponentType: SchemaComponentType[];
    isPubliclyAvailable: boolean;
    category?: any;
  }

  export default {
    name: 'ProductsWizard',
    props: {
      showWizardModal: {
        type: Boolean,
        required: true,
      },
      basePn: {
        type: String,
        default: '',
        required: false,
      },
      product: {
        type: Object as () => Product | null,
        default: null,
      },
    },
    data() {
      return {
        availableProductFamilies: [] as ProductFamily[],
        selectedProductFamily: null as ProductFamily | null,
        availableComponentTypes: [] as ComponentType[],
        compatibilityOptions: {} as { [componentTypeId: string]: CompatibilityOption[] },
        selectedComponents: {} as { [key: string]: CompatibilityOption },
        isEditMode: false,
        editedComponentIndex: -1,
        pnDescription: 'No description available for this configuration',
      };
    },

    mounted() {
      this.loadProductFamilies();
    },

    computed: {
      isWizardModalVisible: {
        get() {
          return this.showWizardModal;
        },
        set(value: boolean) {
          this.$emit('update:showWizardModal', value);
        },
      },
      formattedPartNumber(): string {
        if (!this.selectedProductFamily) return '';

        let partNumber = `${this.selectedProductFamily?.code}-`;

        this.selectedProductFamily.schemaComponentType.forEach((key: SchemaComponentType) => {
          const compatibility = this.selectedComponents[key.componentType.id];
          const componentType = this.availableComponentTypes?.find((componentType: ComponentType) => componentType.id === key.componentType.id);

          if (compatibility?.component) {
            let componentCode = compatibility.isDefaultComponent ? '' : compatibility.component.code;
            partNumber = partNumber.concat(`${componentCode}`);
          } else {
            if (typeof compatibility === 'string') {
              partNumber = partNumber.concat(`${compatibility || '_'}`);
            } else {
              partNumber = partNumber.concat(``);
            }
          }

          if (this.isLastComponentInGroup(this.selectedProductFamily!.schemaComponentType, componentType!) && !partNumber.endsWith('-')) {
            partNumber = partNumber.concat('-');
          }
        });

        if (partNumber.endsWith('-')) {
          partNumber = partNumber.slice(0, -1);
        }
        return partNumber;
      },
      isConfigurationComplete(): boolean {
        const selectedCount = Object.keys(this.selectedComponents).length;
        return selectedCount > 0 && selectedCount === this.availableComponentTypes.length;
      },
    },
    watch: {
      showWizardModal: {
        immediate: true,
        handler(newValue) {
          if (newValue && this.basePn) {
            this.getPnData(this.basePn);
          }
        },
      },
      selectedProductFamily: {
        async handler(newValue) {
          if (!newValue) return;
          await this.loadProductFamilyDetails(newValue);
        },
      },
      selectedComponents: {
        async handler(newValue) {
          if (!this.selectedProductFamily) return;
          await this.updateComponentOptions(newValue);
        },
        deep: true,
      },
      formattedPartNumber: {
        async handler(newValue) {
          if (newValue) {
            await this.getPnDescription();
          }
        },
      },
    },
    methods: {
      async getPnData(pn: string) {
        try {
          const response = await WizardService.internal_info(pn);

          if (response.data && response.data.length > 0) {
            const pnData = response.data[0];
            console.log('Received part number data:', pnData);
            this.pnDescription = pnData.description || 'No description available for this configuration';

            if (pnData.productFamily) {
              this.selectedProductFamily = pnData.productFamily;

              await this.loadProductFamilyDetails(pnData.productFamily);

              await this.$nextTick();

              await new Promise((resolve) => setTimeout(resolve, 500));

              if (pnData.components && pnData.components.length > 0) {
                console.log('Populating components:', pnData.components);

                for (const component of pnData.components) {
                  const componentTypeId = component.id;
                  console.log(`Processing component ${componentTypeId}:`, component);

                  if (!this.compatibilityOptions[componentTypeId] || this.compatibilityOptions[componentTypeId].length === 0) {
                    console.log(`Loading options for component type ${componentTypeId}`);
                    await this.loadComponentOptions(pnData.productFamily.id, componentTypeId, this.selectedComponents);
                    await this.$nextTick();
                  }

                  if (this.compatibilityOptions[componentTypeId]) {
                    console.log(`Available options for ${componentTypeId}:`, this.compatibilityOptions[componentTypeId]);

                    const matchingOption = this.compatibilityOptions[componentTypeId].find(
                      (option: CompatibilityOption) => option.component?.id === component.value.id || option.component?.code === component.value.code
                    );

                    if (matchingOption) {
                      console.log(`Found matching option for ${componentTypeId}:`, matchingOption);
                      this.selectedComponents[componentTypeId] = matchingOption;
                    } else if (component.value.code) {
                      console.log(`Using direct value for ${componentTypeId}:`, component.value.code);
                      this.selectedComponents[componentTypeId] = component.value.code;
                    }
                  } else {
                    console.warn(`No compatibility options found for component ${componentTypeId}`);
                  }
                }
              }
            } else {
              console.warn('No product family found in the response');
            }
          } else {
            console.warn('No data returned from internal_info');
            this.pnDescription = 'No description available for this configuration';
          }
        } catch (error) {
          console.error('Error fetching part number data:', error);
          this.pnDescription = 'Error loading description';
        }
      },

      async getPnDescription() {
        try {
          const response = await WizardService.internal_info(this.formattedPartNumber);

          if (response.data[0].description) {
            this.pnDescription = response.data[0].description;
          } else {
            this.pnDescription = 'No description available for this configuration';
          }
        } catch (error) {
          console.error('Error fetching description:', error);
          this.pnDescription = 'Error loading description';
        }
      },
      isLastComponentInGroup(schemaComponentTypes: SchemaComponentType[], componentType: ComponentType): boolean {
        if (!componentType) return false;
        const currentSchemaInfo = schemaComponentTypes.find(
          (schemaComponentType) => schemaComponentType.componentType.pg_id === componentType.id || schemaComponentType.componentType.id === componentType.id
        );

        if (!currentSchemaInfo) {
          return false;
        }

        const schemasInTheGroup = schemaComponentTypes.filter((schemaComponentType) => currentSchemaInfo?.groupInSchema === schemaComponentType.groupInSchema);
        return schemasInTheGroup.every((schema) => {
          const schemaOrder = schema.orderInGroup || 0;
          const currentOrder = currentSchemaInfo.orderInGroup || 0;
          return (schemaOrder <= currentOrder) as boolean;
        });
      },
      getSelectOption(item: any) {
        if (typeof item === 'string') {
          return item;
        }
        return item;
      },
      arePreviousComponentsSelected(currentIndex: number): boolean {
        return this.availableComponentTypes.slice(0, currentIndex).every((ct: ComponentType) => {
          return !!this.selectedComponents[ct.id];
        });
      },
      resetConfiguration(): void {
        this.selectedComponents = {};
        this.stopEditing();
      },
      stopEditing(): void {
        this.isEditMode = false;
        this.editedComponentIndex = -1;
      },
      handleCloseModal() {
        this.$emit('close');
      },
      handleSaveConfiguration() {
        if (this.basePn && this.basePn !== this.formattedPartNumber) {
          Swal.fire({
            title: 'Confirm Part Number Change',
            text: 'Changing the part number will affect all associated data. Are you sure you want to proceed?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, change it',
            cancelButtonText: 'No, keep original',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$emit('wizard-save', this.formattedPartNumber);
              this.handleCloseModal();
            } else {
              this.handleCloseModal();
            }
          });
        } else {
          this.$emit('wizard-save', this.formattedPartNumber);
          this.handleCloseModal();
        }
      },
      async loadProductFamilies() {
        try {
          const families = await WizardService.getProductFamilies();
          this.availableProductFamilies = families;
        } catch (error: any) {
          console.error('Error fetching product families:', error);
        }
      },
      async loadProductFamilyDetails(family: ProductFamily) {
        try {
          const familyDetails = await WizardService.getProductFamily(family.id);
          // this.availableComponentTypes = familyDetails.componentTypes || [];
          this.availableComponentTypes = (familyDetails.componentTypes || []).filter(
            (ct: ComponentType) => 
              ct.id !== "66bbbfdddbeb6f88b8f202d4" && // Product Length UoM
              ct.id !== "66bbbfdddbeb6f88b8f20075"    // Product Length
          );
          this.compatibilityOptions = {};
          this.selectedComponents = {};

          if (this.availableComponentTypes.length > 0) {
            const firstComponentType = this.availableComponentTypes[0];
            await this.loadComponentOptions(family.id, firstComponentType.id, {});
          }
        } catch (error) {
          console.error('Error loading product family details:', error);
        }
      },
      async loadComponentOptions(familyId: string, componentTypeId: string, currentSelections: any) {
        try {
          const response = await WizardService.getComponentOptions(familyId, componentTypeId, currentSelections);
          const options = Array.isArray(response?.data) ? response.data : [];
          this.compatibilityOptions[componentTypeId] = options;

          if (!this.selectedComponents[componentTypeId]) {
            const defaultOption = options.find((opt: CompatibilityOption) => opt.isDefaultComponent);
            if (defaultOption) {
              this.selectedComponents[componentTypeId] = defaultOption;
            }
          }
        } catch (error) {
          console.error(`Error loading options for component type ${componentTypeId}:`, error);
          this.compatibilityOptions[componentTypeId] = [];
        }
      },
      async updateComponentOptions(currentSelections: any) {
        if (!this.selectedProductFamily) return;

        for (let i = 0; i < this.availableComponentTypes.length; i++) {
          const componentType = this.availableComponentTypes[i];

          if (!this.arePreviousComponentsSelected(i)) continue;

          await this.loadComponentOptions(this.selectedProductFamily.id, componentType.id, currentSelections);
        }
      },
    },
  };
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
  }

  .part-number-display {
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
  }

  .configurator-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .grid-row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 12px;
    align-items: center;
  }

  .config-select {
    width: 100%;
  }

  .spacer {
    width: 100%;
  }

  .button-group {
    grid-column: 6;
    display: flex;
    justify-content: flex-end;
  }

  .preview-section {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 4px;
  }
</style>
