<template>
  <div>
    <!-- Customers Table -->
    <v-data-table :headers="customersHeaders" :items="product.customers" item-key="id" class="elevation-1 mb-6" data-cy="customers-table" density="compact">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Customers</v-toolbar-title>
          <v-col class="d-flex align-center" cols="auto">
            <v-btn @click="addCustomer" class="btn-orange ml-2" data-cy="add-customer-btn">
              <v-icon left>mdi-plus</v-icon>
              Add
            </v-btn>
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="controls-wrapper">
          <span @click="editCustomer(item)"><i class="fa-duotone fa-solid fa-pen-to-square"></i></span>
          <span @click="deleteCustomer(item)"><i class="fa-duotone fa-solid fa-trash"></i></span>
        </div>
      </template>
    </v-data-table>

    <!-- Vendors Table -->
    <v-data-table :headers="vendorsHeaders" :items="product.vendors" item-key="id" class="elevation-1" data-cy="vendors-table" density="compact">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Vendors</v-toolbar-title>
          <v-col class="d-flex align-center" cols="auto">
            <v-btn @click="addVendor" class="btn-orange ml-2" data-cy="add-vendor-btn">
              <v-icon left>mdi-plus</v-icon>
              Add
            </v-btn>
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="controls-wrapper">
          <span @click="editVendor(item)"><i class="fa-duotone fa-solid fa-pen-to-square"></i></span>
          <span @click="deleteVendor(item)"><i class="fa-duotone fa-solid fa-trash"></i></span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Product } from '@/types/pm/product';

  export default defineComponent({
    name: 'CustomersVendorsTab',
    props: {
      product: {
        type: Object as PropType<Product>,
        required: true,
      },
    },
    data() {
      return {
        customersHeaders: [
          { title: 'Customer', value: 'name' },
          { title: 'Customer PN', value: 'productPNFormat' },
          { title: 'Customer Description', value: 'descriptionFormat' },
          { title: 'Actions', value: 'actions', sortable: false, width: '100px' },
        ],
        vendorsHeaders: [
          { title: 'Vendor', value: 'name' },
          { title: 'Vendor PN', value: 'productPNFormat' },
          { title: 'Vendor Description', value: 'descriptionFormat' },
          { title: 'Actions', value: 'actions', sortable: false, width: '100px' },
        ],
      };
    },
    methods: {
      addCustomer() {
        this.$emit('add-customer');
      },
      editCustomer(item: any) {
        this.$emit('edit-customer', item);
      },
      deleteCustomer(item: any) {
        this.$emit('delete-customer', item);
      },
      addVendor() {
        this.$emit('add-vendor');
      },
      editVendor(item: any) {
        this.$emit('edit-vendor', item);
      },
      deleteVendor(item: any) {
        this.$emit('delete-vendor', item);
      },
    },
  });
</script>

<style scoped>
  .controls-wrapper {
    padding: 0 8px;
    gap: 16px;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
  }

  .fa-pen-to-square {
    cursor: pointer;
  }
  .fa-pen-to-square:hover {
    color: #f0ad4e;
  }
  .fa-trash {
    color: #d9534f;
    cursor: pointer;
  }
  .fa-trash:hover {
    color: red;
  }
</style>
