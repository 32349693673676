<!-- src/views/boms/BomsModal.vue -->

<template>
  <v-dialog v-model="modal" max-width="1300px" data-cy="modal" persistent class="custom-dialog">
    <v-card data-cy="modal-card" class="position-relative">
      <v-card-title class="modal-title" data-cy="modal-title">
        <span class="text-h5">{{ modalTitle }}</span>
        <v-icon small @click="closeModal" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text data-cy="modal-content" class="modal-content-scrollable">
        <div class="class-header d-flex align-center justify-space-between">
          <h2 class="mb-3">Product info</h2>
          <div class="bom-container d-flex align-center">
            <div>
              <span>Reference type:</span>
            </div>
            <div v-if="bomData.referenceType" class="bom-type-badge" :class="getStatusColor(bomData.referenceType)">{{ capitalizeFirst(bomData.referenceType!) }}</div>
          </div>
        </div>
        <v-row>
          <v-col cols="12" md="12" class="mt-3">
            <v-row>
              <v-col cols="12" md="4" style="padding: 12px 12px 0px 24px">
                <v-text-field :disabled="thisIsCertified" v-model="bomData.productPn" label="Product Number" variant="underlined"></v-text-field>
              </v-col>
              <v-col cols="12" md="8" style="padding: 12px 12px 0px 24px">
                <v-text-field :disabled="thisIsCertified" v-model="bomData.description" label="Description" variant="underlined"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" style="padding: 12px 12px 0px 24px">
                <v-text-field
                  :disabled="thisIsCertified"
                  v-model="bomData.customerPn"
                  label="Customer PN (optional)"
                  variant="underlined"
                  :error-messages="customerPnError"
                  @blur="validateAwsCustomerPn"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="8" style="padding: 12px 12px 0px 24px">
                <v-text-field :disabled="thisIsCertified" v-model="bomData.customerDescription" label="Customer Description (optional)" variant="underlined"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" style="padding: 12px 12px 0px 24px">
                <v-select
                  :disabled="thisIsCertified"
                  :items="invRouting"
                  v-model="bomData.inventoryRouting"
                  outlined
                  dense
                  placeholder="Select Inventory Routing"
                  style="flex: 1; margin-right: 10px"
                  variant="underlined"
                  hide-details
                  label="Inventory Routing"
                />
              </v-col>
              <v-col cols="12" md="1" style="padding: 12px 12px 0px 24px"></v-col>
              <v-col cols="12" md="4" style="padding: 12px 12px 0px 24px">
                <v-text-field disabled v-model="bomData.createdBy" label="Created by" variant="underlined"></v-text-field>
              </v-col>
              <v-col cols="12" md="2" style="padding: 12px 12px 0px 24px">
                <v-text-field disabled v-model="formattedCreatedAt" label="Created at" variant="underlined"></v-text-field>
              </v-col>
              <v-col cols="12" md="2" style="padding: 12px 12px 0px 24px">
                <v-text-field disabled v-model="formattedUpdatedAt" label="Last Updated" variant="underlined"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-divider style="margin: 40px 0px 16px 0px"></v-divider>
        <h2>Certification ranges</h2>
        <div class="existing-ranges" style="display: flex; justify-content: space-between; align-items: center; margin-top: -4px">
          <p style="margin: 0">
            Existing certification Ranges for family:
            <span>{{ bomData.productPn?.split('-').slice(0, -1).join('-') || '' }}</span>
          </p>
        </div>

        <div class="cert- mt-3">
          <v-data-table :headers="certTableHeaders" :items="certTableItems" item-value="id" class="elevation-1" hide-default-footer>
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="item.id || index" :class="getRowClass(index)">
                <td>
                  <div style="display: flex; align-items: center; gap: 10px">
                    <v-text-field
                      v-if="item.certificationData"
                      class="small-input"
                      v-model="item.certificationData.rangeStart"
                      variant="outlined"
                      hide-details
                      reverse
                      :disabled="!!(isCreated && item.id) || isPm"
                      type="number"
                      :error="!!errors[index]?.rangeStart"
                      @input="handleInput(item, 'rangeStart')"
                      hide-spin-buttons
                      label="Min (excluded)"
                      prefix="meters"
                    />
                    <v-text-field
                      v-if="item.certificationData"
                      class="small-input"
                      v-model="item.certificationData.rangeEnd"
                      variant="outlined"
                      hide-details
                      reverse
                      :disabled="!!(isCreated && item.id) || isPm"
                      type="number"
                      :error="!!errors[index]?.rangeEnd"
                      hide-spin-buttons
                      label="Max (included)"
                      prefix="meters"
                    />
                  </div>
                </td>

                <td
                  ref="certificationDataTd"
                  :style="{
                    textDecoration: !item.certificationData ? 'line-through' : 'none',
                    fontWeight: bomData.productPn === item.productPn ? 'bold' : 'normal',
                  }"
                >
                  {{ item.productPn }}
                </td>
                <td>
                  <span v-if="item.certificationData?.certifiedBy">{{ formatUnknown(item.certificationData.certifiedBy) }}</span>
                  <span v-else></span>
                </td>
                <td>
                  <span v-if="item.certificationData?.certificationAt">{{ formatDate(item.certificationData.certificationAt) }}</span>
                  <span v-else></span>
                </td>
                <td style="display: flex; align-items: center; gap: 0px">
                  <!-- <v-btn :disabled="bomData.productPn === item.productPn" v-if="item.certificationData" class="btn-compare" @click="openCompareModal(item)">Compare</v-btn> -->
                  <v-btn v-if="item.certificationData" class="btn-compare" @click="openCompareModal(item)">Compare</v-btn>
                  <v-btn v-if="!isPm" :disabled="!item.certificationData || isCreated" @click="confirmRevoke(item)" :class="['btn-revoke', { 'ml-4': item.certificationData }]">
                    {{ item.certificationData ? 'Revoke' : 'Revoked' }}
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:bottom>
              <v-data-table v-if="showRowNew" class="row-new" hide-default-footer style="width: 100%">
                <template v-slot:body="{ items }">
                  <tr>
                    <td style="width: 20%; min-width: 270px">
                      <div style="display: flex; align-items: center; gap: 10px">
                        <v-text-field
                          class="small-input"
                          v-model="newLengthMin"
                          variant="outlined"
                          hide-details
                          reverse
                          type="number"
                          label="Min (excluded)"
                          prefix="meters"
                          hide-spin-buttons
                        />
                        <v-text-field
                          class="small-input"
                          v-model="nweLengthMax"
                          variant="outlined"
                          hide-details
                          reverse
                          type="number"
                          label="Max (included)"
                          prefix="meters"
                          hide-spin-buttons
                        />
                      </div>
                    </td>
                    <td :style="{ width: `${tdWidth}px` }">
                      <span class="new-pn">{{ bomData.productPn }}</span>
                    </td>

                    <td style="width: calc(25% + 160px)">
                      <span class="new-add-text text-end">Enter range values and click Add to certify</span>
                    </td>

                    <!-- Button -->
                    <td style="width: 260px">
                      <v-btn style="width: 100%" @click="addToCertify" class="btn-orange">Add range</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </template>
          </v-data-table>
        </div>
        <v-divider style="margin: 30px 0px 16px 0px"></v-divider>
        <div style="display: flex; justify-content: space-between; align-items: center">
          <div style="width: 70%">
            <h2>Bill of Materials</h2>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field :disabled="thisIsCertified" v-model="bomData.reference" label="Reference" variant="underlined"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :disabled="thisIsCertified"
                  :items="routingOptions"
                  v-model="bomData.routing"
                  outlined
                  dense
                  placeholder="Select Routing"
                  style="flex: 1; margin-right: 10px"
                  variant="underlined"
                  hide-details
                  label="Routing"
                />
              </v-col>
            </v-row>
          </div>
          <div v-if="!thisIsCertified"><v-btn @click="openEditModal" class="btn-orange">EDIT BOM LINES</v-btn></div>
        </div>
        <div v-if="!isPm" class="added-boms pb-2">
          <h2>
            Adder for scalable items
            <span style="font-size: 12px; color: #6c757d">
              (
              <span style="color: #000">Product Length:</span>
              {{ adder.productLength }}

              {{ adder.productUom }})
            </span>
          </h2>
          <div style="display: flex; align-items: left; gap: 10px; margin-top: 6px; position: relative">
            <div>
              <v-text-field
                class="small-input adder-field"
                v-model="adder.adderInMeters"
                @input="debouncedHandleAdderChange(String(adder.adderInMeters), 'meters')"
                variant="outlined"
                reverse
                type="number"
                label="Meter"
                hide-spin-buttons
                :error-messages="adderErrors.meters"
                :disabled="bomData.referenceType === 'certified'"
              />
            </div>
            <div>
              <v-text-field
                class="small-input adder-field"
                v-model="adder.adderInFeet"
                @input="debouncedHandleAdderChange(String(adder.adderInFeet), 'feet')"
                variant="outlined"
                reverse
                type="number"
                label="Feet"
                hide-spin-buttons
                :error-messages="adderErrors.feet"
                :style="{ position: 'absolute', left: '123px' }"
                :disabled="bomData.referenceType === 'certified'"
              />
            </div>
          </div>
        </div>
        <BomDataTable :bomList="bomData.bomLines" :showAdder="true" />
        <div class="spacing-div"></div>
      </v-card-text>

      <v-card-actions class="fixed-actions px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn v-if="!isPm" class="btn-cancel mr-2" @click="openSaveAsDialog" data-cy="cancel-button">Save as</v-btn>
        <v-btn class="btn-cancel mr-2" @click="closeModal" data-cy="cancel-button">Cancel</v-btn>
        <v-btn v-if="!isPm" class="btn-save" @click="saveBom" data-cy="save-button">Save</v-btn>
      </v-card-actions>
    </v-card>
    <BomsEditModal :modal="editModal" :item="bomData.bomLines" @update:item="updateItem" @close="editModal = false" />
    <BomsCompareModal :modal="compareModal" :item="sourceItem" :selectedPn="targetPn" :referenceBomPn="referencePn" @close="compareModal = false" />
    <Dialogs :revoke-dialog="revokeDialog" @update:revoke-dialog="revokeDialog = $event" @confirmRevoke="handleRevokeConfirm" />

    <v-dialog v-model="saveAsDialog" max-width="500px">
      <v-card>
        <v-card-title class="modal-title">Save As</v-card-title>
        <v-card-text>
          <v-text-field v-model="newReferenceName" label="New Reference Name" variant="underlined" hide-details></v-text-field>
        </v-card-text>
        <v-card-actions class="px-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn class="btn-cancel" @click="closeSaveAsDialog">Cancel</v-btn>
          <v-btn class="btn-save" :disabled="newReferenceName.length === 0" @click="saveAs">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType, ref } from 'vue';
  import BomDataTable from '@/components/bomg/bom/BomTable.vue';
  import BomsCompareModal from './BomsCompareModal.vue';
  import BomsEditModal from './BomsEditModal.vue';
  import bomService from '@/services/api/bomService';
  import Dialogs from '@/components/common/Dialogs.vue';
  import { useAuthStore } from '@/store/authStore';
  import odooService from '@/services/api/odooService';

  interface Adder {
    productLength: number | null;
    productUom: string | null;
    adderInMeters: number | null;
    adderInFeet: number | null;
  }

  export default defineComponent({
    name: 'BomsModal',
    components: {
      BomDataTable,
      BomsCompareModal,
      BomsEditModal,
      Dialogs,
    },

    props: {
      modal: {
        type: Boolean,
        required: true,
      },
      modalTitle: {
        type: String,
        required: true,
      },
      bomData: {
        type: Object as PropType<any>,
        required: true,
      },
      uomOptions: {
        type: Array as PropType<string[]>,
        default: () => [],
      },
      families: {
        type: Array as () => {
          pnFamily: string;
          bomCount: number;
          boms: any[];
        }[],
        required: true,
      },
      certified: {
        type: Boolean,
      },
      isPm: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data() {
      return {
        currentPage: 1,
        certTableHeaders: [
          { title: 'Length Range', value: 'lengthMin', width: '20%', minWidth: '270px' },
          { title: 'Product Number', value: 'pn', width: '25%' },
          { title: 'Certified By', value: 'certBy', width: '25%' },
          { title: 'Certified On', value: 'certOn', width: '160px' },
          { title: 'Actions', value: 'action', width: '260px' },
        ],
        certTableItems: [] as any[],
        uom: 'Meters',
        newLengthMin: null,
        nweLengthMax: null,
        compareModal: false,
        editModal: false,
        selectedItem: null,
        targetPn: '',
        referencePn: '',
        sourceItem: [] as any[],
        showRowNew: true,
        revokeDialog: false,
        itemToRevoke: null,
        bomsData: [],
        isCreated: false,
        errors: {} as Record<string, { rangeStart?: string; rangeEnd?: string }>,
        isBomChanged: false,
        invRouting: ['Manufacture (VMTO)', 'Purchase', 'Sub-Contract'],
        routingOptions: [] as string[],
        saveAsDialog: false,
        newReferenceName: '',
        tdWidth: 350,
        adder: {} as Adder,
        adderErrors: {
          meters: null as string | null,
          feet: null as string | null,
          error: null as string | null,
          warning: null as string | null,
        },
        customerPnError: null as string | null,
        debouncedHandleAdderChange: null as any,
      };
    },
    mounted() {
      window.addEventListener('resize', this.handleResize);
    },
    created() {
      this.populateAdder();
      this.debouncedHandleAdderChange = this.debounce((value: any, unit: any) => {
        this.handleAdderChange(value, unit);
      }, 600);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.handleResize);
    },
    watch: {
      modal(newVal) {
        if (newVal) {
          console.log('Modal opened.');
          this.populateCertTableItems();
          this.getOdooRoutings();
          if (this.modalTitle === 'Edit BOM') {
            console.log('Editing BOM for families:', this.families);
          } else {
            console.log('Creating a new BOM. Fetching data...');
            this.isCreated = true;
            this.getBomsList();
          }
          this.$nextTick(() => {
            this.validateAwsCustomerPn();
          });
        } else {
          console.log('Modal closed. Resetting data...');
          this.resetData();
        }
      },
      bomData: {
        handler() {
          this.populateCertTableItems();
          this.updateTdWidth();
          this.validateAwsCustomerPn();
        },
      },
      'bomData.productPn': {
        handler() {
          this.populateAdder();
        },
        immediate: true,
      },
      certTableItems: {
        handler() {
          this.validateRanges();
        },
        deep: true,
      },
    },
    computed: {
      thisIsCertified() {
        return this.bomData.referenceType === 'certified';
      },

      computedFamilies() {
        const certifiedBoms = this.bomsData.filter((bom: Bom) => bom.referenceType === 'certified' && bom.certificationData);

        const groupedBoms = certifiedBoms.reduce(
          (acc, bom: Bom) => {
            if (!bom.pnFamily) return acc;

            if (!acc[bom.pnFamily]) {
              acc[bom.pnFamily] = {
                pnFamily: bom.pnFamily,
                bomCount: 0,
                boms: [],
              };
            }

            acc[bom.pnFamily].bomCount++;
            acc[bom.pnFamily].boms.push(bom);

            return acc;
          },
          {} as Record<string, { pnFamily: string; bomCount: number; boms: Bom[] }>
        );

        return Object.values(groupedBoms).sort((a, b) => a.pnFamily.localeCompare(b.pnFamily));
      },

      formattedCreatedAt(): string {
        return this.bomData.createdAt
          ? new Date(this.bomData.createdAt).toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
              hour12: true,
            })
          : '';
      },
      formattedUpdatedAt(): string {
        return this.bomData.updatedAt
          ? new Date(this.bomData.updatedAt).toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
              hour12: true,
            })
          : '';
      },
    },

    methods: {
      populateAdder(): void {
        if (!this.bomData?.productPn) return;

        this.clearAdder();
        const consumedOperation = ['Cut Power Cable', 'Cut Fiber Cable', 'Cut Hybrid Trunk Cable'];
        const parsedData = this.parsePnIntoPieces(this.bomData.productPn);

        if (parsedData) {
          this.adder.productLength = Number(parsedData.formattedLength) || null;
          this.adder.productUom = parsedData.productUom || null;
        }

        const findConsumedOperation = this.bomData.bomLines?.find((line: any) => line?.consumedOperation && consumedOperation.includes(line.consumedOperation));
        console.log('findConsumedOperation', findConsumedOperation);
        if (findConsumedOperation?.cutSize) {
          const quantity = Number(findConsumedOperation.cutSize);

          if (!isNaN(quantity)) {
            if (findConsumedOperation.cutUom === 'Meter' || findConsumedOperation.cutUom === 'Meters') {
              this.adder.adderInMeters = quantity - (this.adder?.productLength ?? 0);
              this.handleAdderChange(this.adder.adderInMeters?.toString(), 'meters');
            } else if (findConsumedOperation.cutUom === 'Foot' || findConsumedOperation.cutUom === 'Feet') {
              this.adder.adderInFeet = quantity - (this.adder?.productLength ?? 0);
              this.handleAdderChange(this.adder.adderInFeet?.toString(), 'feet');
            }
          }
        }
      },

      clearAdder() {
        this.adder = {
          productLength: null,
          productUom: null,
          adderInMeters: null,
          adderInFeet: null,
        };
      },
      parsePnIntoPieces(pn: string) {
        type UnitOfMeasure = 'Meters' | 'Feet' | 'Inches' | 'Centimeters';

        const UoMToShortMapping: Record<string, UnitOfMeasure> = {
          M: 'Meters',
          F: 'Feet',
          I: 'Inches',
          CM: 'Centimeters',
        };

        const productCodePattern = /^(?<baseProduct>.*?)-(?<unit>CM|[MFI])(?<value>\d+)(?:D(?<decimal>\d+))?$/;
        const match = pn.match(productCodePattern);

        if (!match?.groups) return undefined;

        const unit = UoMToShortMapping[match.groups.unit];
        if (!unit) return undefined;

        const value = parseInt(match.groups.value, 10);
        const decimal = match.groups.decimal ? parseInt(match.groups.decimal, 10) : undefined;

        const formattedLength = decimal !== undefined ? `${value}.${decimal}` : `${value}`;

        return {
          basePN: match.groups.baseProduct,
          unit,
          value,
          decimal,
          formattedLength,
          productUom: unit,
        };
      },

      async getBomsList() {
        try {
          const boms = await bomService.bomList();
          this.bomsData = boms;
          this.populateCertTableItems();
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      async getOdooRoutings() {
        try {
          const response = await odooService.odooRoutings();
          this.routingOptions = response.map((item: { name: string }) => item.name).sort((a: string, b: string) => a.localeCompare(b));
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      openCompareModal(item: any) {
        this.selectedItem = item.bomLines;

        if (this.isCreated) {
          this.sourceItem = (this.bomData.bomLines || []).map((line: any) => {
            if (Array.isArray(line.materials) && line.materials.length > 0) {
              return {
                ...line,
                material: line.materials[0],
                materials: undefined,
              };
            }
            return line;
          });
        } else {
          this.sourceItem = this.bomData.bomLines || [];
        }
        this.targetPn = this.bomData.productPn;
        this.referencePn = item.productPn;
        this.compareModal = true;
      },
      closeModal() {
        this.$emit('close');
      },
      openEditModal() {
        this.editModal = true;
      },
      validateAdder(): { isValid: boolean; hasWarning: boolean } {
        this.adderErrors.error = null;
        this.adderErrors.warning = null;

        const metersValue = this.adder.adderInMeters;

        if (metersValue === null) {
          return { isValid: false, hasWarning: false };
        }

        if (metersValue < 0 || metersValue > 10) {
          this.adderErrors.error = `Adder must be between 0 and 10 meters`;
          return { isValid: false, hasWarning: false };
        }

        if (metersValue < 0.2 || metersValue > 3) {
          this.adderErrors.warning = `Warning: Recommended adder range is between 0.2 and 3 meters`;
          return { isValid: true, hasWarning: true };
        }

        return { isValid: true, hasWarning: false };
      },
      addToCertify() {
        const validation = this.validateAdder();

        if (!validation.isValid) {
          this.$log.showError(this.adderErrors.error || 'Invalid adder value');
          return;
        }

        if (validation.hasWarning) {
          this.$log.showWarning(this.adderErrors.warning || 'Adder value outside recommended range');
        }

        if (!this.newLengthMin || !this.nweLengthMax) {
          this.$log.showError('Please enter range values');
          return;
        }

        if (!this.newLengthMin || !this.nweLengthMax) {
          this.$log.showError('Please enter range values');
          return;
        }

        const min = Number(this.newLengthMin);
        const max = Number(this.nweLengthMax);

        if (min > max) {
          this.$log.showError('The start value cannot be greater than the end value');
          return;
        }

        const newRange = {
          rangeUnit: this.uom.toLowerCase(),
          rangeStart: min,
          rangeEnd: max,
          certifiedBy: useAuthStore().user?.name || 'Unknown',
          certificationAt: new Date().toISOString(),
        };

        this.certTableItems.push({
          ...this.bomData,
          referenceType: 'certified',
          productPn: this.bomData.productPn,
          certificationData: newRange,
        });

        this.newLengthMin = null;
        this.nweLengthMax = null;
        this.showRowNew = true;
        this.validateRanges();
      },

      async saveBom() {
        this.validateAwsCustomerPn();
        if (!this.validateReference()) {
          return;
        }
        if (Object.keys(this.errors).length > 0) {
          this.$log.showError('Cannot save certification ranges, Please make sure that the certification ranges are not overlapping.');
          return;
        }

        if (this.certified) this.isCreated = true;
        if (this.thisIsCertified === false) {
          // this.certTableItems.push(this.bomData);
          this.certTableItems.unshift(this.bomData);
        }

        const currentPnFamily = this.bomData.productPn?.split('-').slice(0, -1).join('-') || '';
        this.certTableItems.forEach((item) => {
          item.pnFamily = currentPnFamily;
        });
        const data = this.certTableItems;

        try {
          if (this.isCreated) {
            const newBom = data.find((item) => !item.id);

            if (!newBom) {
              this.bomData.bomLines = this.bomData.bomLines.map((line: any) => ({
                ...line,
                material: Array.isArray(line.materials) && line.materials.length > 0 ? line.materials[0] : line.material,
              }));
              this.bomData.createdBy = useAuthStore().user?.name || 'Unknown';

              await bomService.bomCreate(this.bomData);
              this.$log.showSuccessMessage('BOM updated successfully.');
              this.$emit('save');
              return;
            }

            if (newBom.certificationData) {
              const { rangeStart, rangeEnd } = newBom.certificationData;
              const start = Number(rangeStart);
              const end = Number(rangeEnd);

              if (isNaN(start) || isNaN(end)) {
                return;
              }

              newBom.certificationData.rangeStart = start;
              newBom.certificationData.rangeEnd = end;
            }

            if (Array.isArray(newBom.bomLines)) {
              newBom.bomLines = newBom.bomLines.map((line: any) => ({
                ...line,
                material: Array.isArray(line.materials) && line.materials.length > 0 ? line.materials[0] : line.material,
                materials: undefined,
              }));
            }

            console.log('Processed BOM with material:', newBom);
            newBom.createdBy = useAuthStore().user?.name || 'Unknown';

            await bomService.bomCreate(newBom);
            this.$log.showSuccessMessage('New BOM saved successfully.');
            this.$emit('save');
          } else {
            const updateResponse = await bomService.bomUpdateMany(data);
            if (updateResponse) this.$log.showSuccessMessage('BOM saved successfully');
            console.log('BOM saved:', data);
            this.$emit('save');
          }
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      openSaveAsDialog() {
        this.newReferenceName = `${this.bomData.reference} - Copy`;
        this.saveAsDialog = true;
      },
      closeSaveAsDialog() {
        this.saveAsDialog = false;
      },
      async saveAs() {
        if (!this.validateReference()) {
          return;
        }
        const newBomData = {
          ...this.bomData,
          certificationData: undefined,
        };

        newBomData.reference = this.newReferenceName;
        newBomData.referenceType = 'reference';
        newBomData.id = undefined;
        newBomData.createdBy = useAuthStore().user?.name || 'Unknown';
        newBomData.createdAt = undefined;
        newBomData.updatedBy = undefined;
        newBomData.updatedAt = undefined;

        this.saveAsDialog = false;

        try {
          await bomService.bomCreate(newBomData);

          this.$log.showSuccessMessage('BOM saved successfully as a new reference.');
          this.$emit('save');
          this.closeModal();
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      handleNumberInput(e: Event, item: BomLine, field: 'cutSize' | 'cutQuantity' | 'quantity') {
        const value = Number((e.target as HTMLInputElement).value);
        item[field] = value;
      },

      capitalizeFirst(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      },

      populateCertTableItems() {
        this.showRowNew = true;
        this.certTableItems = [];

        const productPn = this.bomData.productPn || '';
        const pnBase = productPn.substring(0, productPn.lastIndexOf('-'));

        const family = this.families.find((family) => family.pnFamily === pnBase) || this.computedFamilies.find((family) => family.pnFamily === pnBase);
        if (!family) {
          console.warn('No matching family found');
          return;
        }
        const unsortedItems = family.boms.map((item, index) => ({
          ...item,
          itemIndex: index,
        }));
        this.certTableItems = this.sortCertTableItems(unsortedItems);
        this.showRowNew = !this.certTableItems.some((item) => item.productPn === productPn);
      },
      sortCertTableItems(items: any) {
        return items.sort((a: any, b: any) => {
          const startA = a.certificationData ? a.certificationData.rangeStart : 0;
          const startB = b.certificationData ? b.certificationData.rangeStart : 0;

          if (startA !== startB) {
            return startA - startB;
          }

          const endA = a.certificationData ? a.certificationData.rangeEnd : 0;
          const endB = b.certificationData ? b.certificationData.rangeEnd : 0;

          return endA - endB;
        });
      },

      toggleUOM() {
        this.uom = this.uom === 'Meters' ? 'Feet' : 'Meters';
      },
      resetData() {
        this.newLengthMin = null;
        this.nweLengthMax = null;
        this.showRowNew = true;
      },

      confirmRevoke(item: any) {
        this.itemToRevoke = item;
        this.revokeDialog = true;
      },
      handleRevokeConfirm() {
        if (this.itemToRevoke) {
          this.revoke(this.itemToRevoke);
        }
        this.revokeDialog = false;
        this.itemToRevoke = null;
      },
      revoke(item: any) {
        delete item.certificationData;
        if (item.referenceType) {
          item.referenceType = 'reference';
        }
        this.certTableItems = [...this.certTableItems];
      },

      validateRanges() {
        this.errors = {};

        this.certTableItems.forEach((item, itemIndex) => {
          const certificationData = item.certificationData;
          if (!certificationData || certificationData.rangeStart == null || certificationData.rangeEnd == null) {
            return;
          }

          const rangeStart = Number(certificationData.rangeStart);
          const rangeEnd = Number(certificationData.rangeEnd);

          if (rangeStart > rangeEnd) {
            this.errors[itemIndex] = { rangeStart: 'Start > End', rangeEnd: 'Start > End' };
          }
        });
      },
      handleInput(item: any, field: 'rangeStart' | 'rangeEnd') {
        this.validateRanges();
      },

      getRowClass(index: number) {
        // console.log(this.errors);
        return this.errors[index] ? 'error-row' : '';
      },

      formatDate(dateString: string): string {
        return new Date(dateString).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
      },
      formatUnknown(user: string) {
        if (user === 'Unknown') return '-';
        return user;
      },

      getStatusColor(status: string): string {
        const statusColors = {
          reference: 'orange',
          certified: 'green',
          broken: '#FEE2E2',
          validation: 'primary',
        };
        return `badge_` + statusColors[status as keyof typeof statusColors] || '#F3F4F6';
      },
      updateItem(updatedBom: any) {
        this.bomData.bomLines = updatedBom;
        this.isBomChanged = true;
      },
      updateTdWidth() {
        // Костыль для получения ширины элемента
        this.$nextTick(() => {
          const td = this.$refs.certificationDataTd;
          if (td && (td as HTMLElement[])[0]) {
            this.tdWidth = (td as HTMLElement[])[0].offsetWidth;
            console.log('Width of certificationDataTd:', this.tdWidth);
          }
        });
      },
      handleResize() {
        this.updateTdWidth();
      },
      validateReference(): boolean {
        if (!this.bomData.reference || this.bomData.reference.trim() === '' || !this.bomData.reference) {
          this.$log.showError('BOM Reference is required');
          return false;
        }
        return true;
      },
      convertToMeters(feet: number): number {
        return feet * 0.3048;
      },

      convertToFeet(meters: number): number {
        return meters * 3.28084;
      },

      handleAdderChange(value: string | null, unit: 'meters' | 'feet') {
        const numValue = value ? Number(value) : null;

        this.adderErrors.meters = null;
        this.adderErrors.feet = null;

        if (numValue === null) {
          this.adder.adderInMeters = null;
          this.adder.adderInFeet = null;
          return;
        }

        if (unit === 'meters') {
          if (numValue < 0 || numValue > 10) {
            this.adderErrors.meters = 'Adder must be between 0 and 10 meters';
          }
          this.adder.adderInFeet = Math.round(this.convertToFeet(numValue));
          this.adder.adderInMeters = numValue;
        } else {
          const metersValue = this.convertToMeters(numValue);
          if (metersValue < 0 || metersValue > 10) {
            this.adderErrors.feet = 'Adder must be between 0 and 32.81 feet (10 meters)';
          }
          this.adder.adderInMeters = Math.round(metersValue * 10) / 10;
          this.adder.adderInFeet = numValue;
        }

        if (this.bomData.bomLines && this.bomData.bomLines.length > 0) {
          const consumedOperation = ['Cut Power Cable', 'Cut Fiber Cable', 'Cut Hybrid Trunk Cable'];

          this.bomData.bomLines.forEach((line: any) => {
            if (line.consumedOperation && consumedOperation.includes(line.consumedOperation)) {
              const productLength = this.adder.productLength || 0;
              let newCutSize;

              if (line.cutUom === 'Meter' || line.cutUom === 'Meters') {
                if (this.adder.productUom === 'Meters') {
                  newCutSize = productLength + (this.adder.adderInMeters || 0);
                } else if (this.adder.productUom === 'Feet') {
                  newCutSize = this.convertToMeters(productLength) + (this.adder.adderInMeters || 0);
                }
              } else if (line.cutUom === 'Foot' || line.cutUom === 'Feet') {
                if (this.adder.productUom === 'Feet') {
                  newCutSize = productLength + (this.adder.adderInFeet || 0);
                } else if (this.adder.productUom === 'Meters') {
                  newCutSize = this.convertToFeet(productLength) + (this.adder.adderInFeet || 0);
                }
              }

              if (newCutSize !== undefined) {
                line.cutSize = Math.round(newCutSize * 10) / 10;
              }
            }
          });
        }
      },
      validateAwsCustomerPn() {
        const pn = this.bomData.customerPn;
        this.customerPnError = null;

        if (pn && pn.startsWith('VP1')) {
          if (pn.length !== 13) {
            this.customerPnError = 'AWS part numbers should be 13 characters';
          }
        }
      },
      debounce(fn: Function, delay: number) {
        let timeoutId: number | null = null;
        return function (...args: any[]) {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          timeoutId = setTimeout(() => {
            fn(...args);
            timeoutId = null;
          }, delay);
        };
      },
    },
  });
</script>

<style scoped>
  .spacing-div {
    height: 75px;
    width: 100%;
  }
  .position-relative {
    position: relative;
  }

  .modal-content-scrollable {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
    padding-bottom: 100px;
  }

  .fixed-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  .badge_orange {
    background-color: #ffedcc !important;
    color: orange;
    border: 1px solid orange;
  }
  .badge_green {
    background-color: #d1f1e0 !important;
    color: green;
    border: 1px solid green;
  }
  .badge_primary {
    background-color: #cce3ff !important;
    color: #3b82f6;
    border: 1px solid #3b82f6;
  }
  .badge_#FEE2E2 {
    background-color: #fee2e2 !important;
    color: red;
    border: 1px solid red;
  }
  .modal-title {
    background-color: #3b516b;
    color: white;
  }

  .bom-info-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    margin-bottom: 24px;
  }

  .info-section {
    min-width: 0;
  }

  .field-label {
    color: #64748b;
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 400;
  }

  .field-value {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .field-value-full {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
  }

  .bom-type-badge {
    display: inline-block;

    padding: 4px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
  }

  .mt-6 {
    margin-top: 24px;
  }
  ::v-deep(.v-data-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table tr:hover) {
    background-color: #f7f7f7;
  }

  .existing-ranges p {
    font-size: 16px;
    color: #141826;
  }
  .existing-ranges span {
    font-size: 18px;
    margin-left: 8px;
    font-weight: bold;
    color: #141826;
  }
  .btn-compare {
    width: 105px;
    background-color: #4caf50;
    color: white;
    border: 1px solid #4caf50;
  }
  .btn-compare:hover {
    background-color: white;
    color: #4caf50;
    border: 1px solid #4caf50;
  }
  .btn-compare:disabled {
    background-color: #f5f5f5;
    color: #c4c4c4;
    border: 1px solid #c4c4c4;
  }
  .btn-revoke {
    width: 105px;
    background-color: #f44336;
    color: white;
    border: 1px solid #ff5252;
  }
  .btn-revoke:hover {
    background-color: white;
    color: #f44336;
    border: 1px solid #ff5252;
  }
  .btn-revoke:disabled {
    background-color: #f5f5f5;
    color: #c4c4c4;
    border: 1px solid #c4c4c4;
  }
  .btn-change-uom {
    background-color: white;
    color: grey;
  }
  .newPn {
    color: #64748b;
  }
  .row-new {
    /* border-top: 1px solid gray; */
    background: #f8fafc;
    font-size: 14px;
  }

  ::v-deep(.v-messages__message) {
    color: #f44336;
    font-size: 12px;
    padding-top: 4px;
  }

  ::v-deep(.small-input .v-input__control) {
    width: 112px;
    height: 34px;
    min-height: 34px;
    background-color: rgb(255, 255, 255);
  }

  ::v-deep(.small-input .v-input__control input) {
    padding: 8px 4px 25px 8px;
  }

  ::v-deep(.small-input .v-input__control .v-input__control--outlined) {
    border-width: 1px;
    color: red;
  }
  ::v-deep(.v-field-label) {
    font-size: 11px;
    margin: -12px 15px 0px 0px;
    text-overflow: clip;
    white-space: nowrap;
  }
  ::v-deep(.v-text-field__prefix) {
    padding: 0px 0px 0px 0px;
    margin: -5px 4px 0px 0px;
  }

  ::v-deep(.v-text-field__prefix__text) {
    color: #64748b;
    font-size: 10px;
  }

  .error-row {
    background-color: rgba(255, 0, 0, 0.1);
    color: red;
  }

  .class-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bom-container {
    margin-bottom: 14px;
  }

  .bom-type-badge {
    margin-left: 5px;
  }

  ::v-deep(.v-field--disabled) {
    opacity: 1 !important;
  }
  /* .added-boms {
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 16px;
    border-left: 4px solid mediumaquamarine;
  } */
  .added-boms h2 {
    font-size: 18px;
    font-weight: 600;
    color: #141826;
  }
  ::v-deep(.adder-field input:disabled) {
    opacity: 0.2 !important;
  }
  ::v-deep(.v-input__details) {
    padding-left: 0px !important;
  }
</style>
