<!-- src/views/boms/CertifiedProductFamilies.vue -->
<template>
  <div class="certified-product-families-container">
    <input type="file" accept=".xlsx, .xls" ref="pnFileInput" style="display: none" @change="(e) => pnImportHandler(e, selectedFamily)" />
    <v-data-table
      :headers="headers"
      :items="families"
      class="families-table"
      data-cy="certified-product-families-table"
      :loading="loading"
      :style="isPm ? 'margin: -10px !important' : ''"
    >
      <template v-if="isPm" v-slot:headers></template>
      <template v-slot:item.pnFamily="{ item }">
        <div v-if="!isPm" class="d-flex align-center family-cell" @click="toggleFamily(item.pnFamily)">
          <v-icon size="small" class="mr-2">
            {{ expandedFamilies.includes(item.pnFamily) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
          </v-icon>
          {{ item.pnFamily }}
          <v-chip class="ml-2" size="small" color="primary">{{ formatRange(item.bomCount) }}</v-chip>
          <v-spacer></v-spacer>
          <v-btn v-if="expandedFamilies.includes(item.pnFamily) && !isPm" class="btn-orange" @click.stop="triggerPnFileInput(item)">
            <i class="fa-solid fa-down-to-bracket"></i>
            &nbsp Bulk Create
          </v-btn>
          <v-btn v-if="expandedFamilies.includes(item.pnFamily) && !isPm" class="btn-orange ml-2" @click.stop="openMassUpdateModal(item)">
            <i class="fa-solid fa-arrows-rotate"></i>
            &nbsp Update boms in odoo
          </v-btn>
        </div>
        <div v-if="expandedFamilies.includes(item.pnFamily)" class="family-details mt-2">
          <v-table density="compact" class="nested-table">
            <thead>
              <tr>
                <th class="certification-range">Certification Range</th>
                <th class="part-number">Certified Part Number</th>
                <th class="created-by">Certified By</th>
                <th class="timestamps">Timestamps</th>
                <th class="actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="bom in item.boms" :key="bom.id" class="nested-row">
                <td class="certification-range">{{ getCertificationRange(bom) }}</td>
                <td class="part-number">{{ bom.productPn }}</td>
                <td class="created-by">{{ formatUnknown(bom.certificationData!.certifiedBy) }}</td>
                <td class="timestamps">
                  <div class="d-flex flex-column">
                    <span class="text-caption">Created: {{ formatDate(bom.createdAt) }}</span>
                    <span class="text-caption text-grey">Last updated: {{ updateEventformatDate(bom.updatedAt) }}</span>
                  </div>
                </td>
                <td class="actions">
                  <div class="controls-wrapper">
                    <span @click.stop="openModal(bom)">
                      <i class="fa-duotone fa-solid fa-pen-to-square"></i>
                    </span>
                    <span v-if="!isPm" @click.stop="confirmRevoke(bom)">
                      <i class="fa-duotone fa-solid fa-trash"></i>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>
      </template>
    </v-data-table>
  </div>
  <MassUpdate
    :modal="showMassUpdateModal"
    :model-value="showMassUpdateModal"
    :selected-family="selectedFamily"
    @update:modelValue="showMassUpdateModal = $event"
    @close="showMassUpdateModal = false"
  />
  <Dialogs :revoke-dialog="revokeDialog" @update:revoke-dialog="revokeDialog = $event" @confirmRevoke="handleRevokeConfirm" />
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import Dialogs from '@/components/common/Dialogs.vue';
  import bomService from '@/services/api/bomService';
  import MassUpdate from './BomsMassUpdateModal.vue';
  import * as XLSX from 'xlsx';
  export default defineComponent({
    name: 'CertifiedProductFamilies',

    components: {
      Dialogs,
      MassUpdate,
    },

    props: {
      isPm: {
        type: Boolean,
        default: false,
        required: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      families: {
        type: Array as () => {
          pnFamily: string;
          bomCount: number;
          boms: Bom[];
        }[],
        required: true,
      },
    },
    emits: ['open-modal', 'confirm-delete', 'confirm-revoke'],

    data() {
      return {
        expandedFamilies: [] as string[],
        headers: [
          {
            title: 'Products',
            key: 'pnFamily',
            sortable: true,
          },
        ],
        revokeDialog: false,
        itemToRevoke: null as Bom | null,
        showMassUpdateModal: false,
        selectedFamily: null as any,
      };
    },
    watch: {
      families: {
        immediate: true,
        handler(newFamilies) {
          if (this.isPm && newFamilies.length > 0) {
            this.expandedFamilies = newFamilies.map((family: any) => family.pnFamily);
          }
        },
      },
      isPm: {
        immediate: true,
        handler(newValue) {
          if (newValue && this.families.length > 0) {
            this.expandedFamilies = this.families.map((family: any) => family.pnFamily);
          }
        },
      },
    },

    methods: {
      toggleFamily(pnFamily: string) {
        const index = this.expandedFamilies.indexOf(pnFamily);
        if (index === -1) {
          this.expandedFamilies.push(pnFamily);
        } else {
          this.expandedFamilies.splice(index, 1);
        }
      },

      formatDate(dateString: string): string {
        return new Date(dateString).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
      },

      getCertificationRange(bom: Bom): string {
        if (!bom.certificationData) return 'Not available';
        const certifiedData = bom.certificationData;
        const unitOfMeasurement = certifiedData.rangeUnit.charAt(0).toUpperCase() + certifiedData.rangeUnit.slice(1);
        return `${certifiedData.rangeStart} ${unitOfMeasurement} - ${certifiedData.rangeEnd} ${unitOfMeasurement}`;
      },

      formatRange(count: number): string {
        if (count <= 1) return `${count} Certified Range`;
        return `${count} Certified Ranges`;
      },

      updateEventformatDate(dateString: string): string {
        const date = new Date(dateString);
        const now = new Date();
        if (date.toDateString() === now.toDateString()) {
          return `Today, ${date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          })}`;
        }

        return date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        });
      },

      formatUnknown(user: string) {
        if (user === 'Unknown') return '-';
        return user;
      },

      openModal(bom: Bom) {
        this.$emit('open-modal', 'edit', bom);
      },

      confirmRevoke(bom: any) {
        this.itemToRevoke = bom;
        this.revokeDialog = true;
      },

      async handleRevokeConfirm() {
        if (this.itemToRevoke) {
          try {
            const { updatedAt, createdAt, ...cleanedBom } = this.itemToRevoke;
            delete cleanedBom.certificationData;
            cleanedBom.referenceType = 'reference';

            await bomService.bomUpdate(cleanedBom.id, cleanedBom);

            if (this.itemToRevoke) {
              delete this.itemToRevoke.certificationData;
              this.itemToRevoke.referenceType = 'reference';
            }
            this.$log.showSuccessMessage('BOM certification revoked successfully');
            this.$forceUpdate();
          } catch (error: any) {
            this.$error.view(error);
          }
        }
        this.revokeDialog = false;
        this.itemToRevoke = null;
      },
      openMassUpdateModal(family: any) {
        this.selectedFamily = family;
        this.showMassUpdateModal = true;
      },

      closeMassUpdateModal() {
        this.showMassUpdateModal = false;
        this.selectedFamily = null;
      },
      pnImportHandler(event: Event, item: { pnFamily: string; bomCount: number; boms: any[] }) {
        const input = event.target as HTMLInputElement;
        const file = input?.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = e.target?.result;
          if (!(data instanceof ArrayBuffer)) return;

          const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
          if (!validTypes.includes(file.type)) {
            alert('Please upload a valid Excel file (.xlsx or .xls)');
            return;
          }

          try {
            const workbook = XLSX.read(new Uint8Array(data), { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            const importedData: any[] = XLSX.utils.sheet_to_json(worksheet);
            const hasProductPartNumber = importedData.length > 0 && 'PN' in importedData[0];

            if (!hasProductPartNumber) {
              this.$log.showError('Required column "PN" is missing in the Excel file');
              return;
            }

            if (!importedData.length) {
              this.$log.showError('No data imported in the Excel file');
              return;
            }

            const validRows: any[] = [];
            const invalidRows: string[] = [];

            importedData.forEach((row) => {
              const pnFamily = row['PN'].split('-').slice(0, -1).join('-');
              if (pnFamily === item.pnFamily) {
                validRows.push(row);
              } else {
                invalidRows.push(row['PN']);
              }
            });

            if (invalidRows.length > 0) {
              this.$log.showWarning(`The following PNs don't belong to Base PN: ${item.pnFamily}: ${invalidRows.join(', ')}`, 5000);
            }

            if (validRows.length === 0) {
              this.$log.showError(`None of the PNs in the file are valid for the selected Base PN ${item.pnFamily}`);
              return;
            }

            const processedData = validRows.map((row) => ({
              reference: '',
              pnFamily: item.pnFamily,
              numberOfBoms: 1,
              productPn: String(row['PN']),
            }));

            input.value = '';
            await this.massOdooCreate(processedData, item.pnFamily);
          } catch (error) {
            console.error('Error processing Excel file:', error);
            this.$log.showError('Error processing Excel file. Please check the file format.');
          }
        };

        reader.onerror = (error) => {
          console.error('Error reading file:', error);
          this.$log.showError('Error reading file. Please try again.');
          return [];
        };

        reader.readAsArrayBuffer(file);
      },

      triggerPnFileInput(item: any) {
        const fileInput = this.$refs.pnFileInput as HTMLInputElement;
        if (fileInput) {
          this.selectedFamily = item;
          fileInput.click();
        }
      },
      async massOdooCreate(data: any, pnFamily: string) {
        try {
          await bomService.bomOdooMassCreate(data);
          this.$log.showSuccessLong(`Processed ${data.length} products for Base PN: ${pnFamily} <br> <b>Please check the corresponding Slack channel to see any updates.</b>`);
        } catch (error) {
          console.error('Error processing Excel file:', error);
          this.$log.showError('Error processing Excel file. Please check the file format.');
        }
      },
    },
  });
</script>

<style scoped>
  .certified-product-families-container {
    margin-top: 20px;
  }

  .family-cell {
    cursor: pointer;
    padding: 8px 0;
  }

  .family-details {
    background-color: #f8fafc;
    border-radius: 4px;
    margin: 8px 0;
  }

  .nested-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  /* Column width definitions */
  .certification-range {
    width: 25%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .part-number {
    width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .created-by {
    width: 15%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .timestamps {
    width: 20%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .actions {
    width: 10%;
    text-align: center;
  }

  .nested-table th {
    background-color: #f1f5f9;
    color: #475569;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: left;
    padding: 8px 16px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .nested-table td {
    padding: 8px 16px;
    font-size: 0.875rem;
    color: #1e293b;
    border-bottom: 1px solid #e2e8f0;
  }

  .nested-row:hover {
    background-color: #f1f5f9;
  }

  .controls-wrapper {
    padding: 0 8px;
    gap: 15px;
    display: flex;
    font-size: 18px;
  }

  .controls-wrapper span {
    cursor: pointer;
  }

  .controls-wrapper span > i:hover {
    color: orange;
  }

  .controls-wrapper span > i.fa-pen-to-square:hover {
    color: rgb(64, 64, 194);
  }

  .controls-wrapper span > i.fa-trash:hover {
    color: red;
  }

  .nested-table td,
  .nested-table th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
